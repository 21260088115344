.row {
  margin-left: 0 !important;
  margin-right: 0 !important; }
  .row div {
    padding-left: 0 !important;
    padding-right: 0 !important; }

h1 {
  font-size: 45px !important; }

h2 {
  font-size: 40px !important; }

h3 {
  font-size: 35px !important; }

h4 {
  font-size: 30px !important; }

h5 {
  font-size: 25px !important; }

h6 {
  font-size: 20px !important; }

p, a, ul, ol, span, li {
  font-size: 17px !important; }

h1, h2, h3, h4, h5, h6, p, a {
  margin: 0 !important; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lexend', sans-serif !important; }

p, a {
  font-family: 'Manrope', sans-serif !important; }

.main-wrapper {
  max-width: 1400px;
  margin: 0 auto; }

/* Announcement Bar */
.announcement-bar p {
  font-family: 'Lexend', sans-serif !important;
  text-transform: uppercase;
  padding: 12px 10px !important;
  font-weight: 400;
  font-size: 16px !important; }
  .announcement-bar p span {
    font-family: 'Lexend', sans-serif !important;
    font-weight: 600;
    font-size: 16px !important; }

@media (max-width: 767px) {
  .announcement-bar p {
    font-size: 12px !important; }
    .announcement-bar p span {
      font-size: 12px !important; } }
/* Link */
.link a {
  text-transform: uppercase;
  padding: 15px 30px;
  font-weight: 600;
  font-size: 15px !important;
  letter-spacing: 1px;
  border-radius: 50px; }
  .link a:hover {
    text-decoration: none;
    transition: .3s; }

/* About Link Block */
.aboutLinkBlock {
  padding: 100px 0;
  position: relative;
  background-size: cover !important;
  background-position: center !important; }
  .aboutLinkBlock::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0c3251;
    opacity: 0.75;
    left: 0;
    top: 0; }
  .aboutLinkBlock .content_block {
    position: relative;
    z-index: 1;
    border-left: 35px solid #dddee0;
    border-right: 35px solid #dddee0; }
    .aboutLinkBlock .content_block .content {
      padding: 75px 20px;
      max-width: 850px;
      margin: 0 auto;
      text-align: center; }
      .aboutLinkBlock .content_block .content p {
        max-width: fit-content;
        margin: 0 auto !important;
        text-transform: uppercase;
        color: #6a7c8d;
        font-weight: 500;
        letter-spacing: 1px;
        font-size: 20px !important;
        border-bottom: 2px solid #6a7c8d; }
      .aboutLinkBlock .content_block .content h1 {
        padding: 25px 0 50px;
        color: white;
        font-weight: 400; }
      .aboutLinkBlock .content_block .content .link a {
        background-color: white;
        color: #0c3251; }
        .aboutLinkBlock .content_block .content .link a:hover {
          background-color: #8baac2;
          color: white; }

@media (max-width: 767px) {
  .aboutLinkBlock {
    padding: 50px 0; }
    .aboutLinkBlock .content_block {
      border-left: 20px solid #dddee0;
      border-right: 20px solid #dddee0; }
      .aboutLinkBlock .content_block .content h1 {
        font-size: 35px !important; } }
/* FAQ */
.bouncy-transition, .faqPage .faqs .icon {
  transition: 0.2s ease-out all 0.2s; }
  .bouncy-transition:before, .faqPage .faqs .icon:before, .bouncy-transition:after, .faqPage .faqs .icon:after {
    transition: all 0.6s cubic-bezier(0.46, -0.41, 0.3, 1.52) 0.1s; }
  .open-state .bouncy-transition, .open-state .faqPage .faqs .icon, .faqPage .faqs .open-state .icon {
    transition: all 0.6s cubic-bezier(0.46, -0.41, 0.3, 1.52) 0.1s; }
    .open-state .bouncy-transition:before, .open-state .faqPage .faqs .icon:before, .faqPage .faqs .open-state .icon:before, .open-state .bouncy-transition:after, .open-state .faqPage .faqs .icon:after, .faqPage .faqs .open-state .icon:after {
      transition: all 0.6s cubic-bezier(0.46, -0.41, 0.3, 1.52) 0.1s; }

.faqPage .faqs {
  padding: 100px 20px;
  max-width: 1000px;
  margin: 0 auto; }
  .faqPage .faqs h4 {
    color: black;
    text-transform: initial;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.5px; }
  .faqPage .faqs .accordian_block .link {
    position: relative;
    padding: 20px 25px 25px 5px;
    cursor: pointer;
    border-top: 2px solid #dddee0; }
    .faqPage .faqs .accordian_block .link p {
      font-weight: 300;
      color: black;
      font-size: 20px !important;
      letter-spacing: 1px; }
  .faqPage .faqs .accordian_block .mblock:nth-child(1) .link {
    border-top: none; }
  .faqPage .faqs .accordian_block .content {
    display: none;
    padding: 10px 5px; }
    .faqPage .faqs .accordian_block .content p {
      font-weight: 300;
      padding-bottom: 15px;
      line-height: 1.75;
      letter-spacing: 0.5px; }
  .faqPage .faqs .title div {
    width: 125px;
    height: 2.5px;
    background-color: #0c3251;
    margin: 25px auto 15px auto; }
  .faqPage .faqs .title h4 {
    padding-bottom: 75px; }
  .faqPage .faqs .contact_block {
    padding-top: 100px;
    text-align: center; }
    .faqPage .faqs .contact_block p {
      padding: 15px 0;
      letter-spacing: 0.5px;
      color: black; }
      .faqPage .faqs .contact_block p a {
        font-weight: 600;
        color: #0c3251;
        border-bottom: none; }
        .faqPage .faqs .contact_block p a:hover {
          color: black;
          border-bottom: 1px solid black;
          text-decoration: none;
          transition: .3s; }
    .faqPage .faqs .contact_block svg {
      max-width: 60px; }
  .faqPage .faqs .icon {
    position: absolute;
    right: 5px;
    top: 30px; }
    .faqPage .faqs .icon:before, .faqPage .faqs .icon:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      transform: translate(-50%, -50%);
      border-bottom: 2px solid black; }
    .faqPage .faqs .icon:before {
      transform: translate(-50%, -50%) rotate(90deg); }
    .faqPage .faqs .icon.open-state:before, .faqPage .faqs .icon.open-state:after {
      width: 50%;
      margin-top: -5px; }
    .faqPage .faqs .icon.open-state:before {
      transform: translate(0, 0) rotate(0); }
    .faqPage .faqs .icon.open-state:after {
      left: 0;
      transform: translate(0, 0) rotate(-180deg); }

@media (max-width: 767px) {
  .faqPage .faqs {
    padding: 50px 20px; }
    .faqPage .faqs .contact_block {
      padding-top: 50px; }
    .faqPage .faqs .title h4 {
      padding-bottom: 50px; } }
/* Generic Policy Page Banner */
.genericPolicyPageBanner {
  position: relative;
  background-size: cover !important;
  background-position: top center !important;
  overflow: hidden; }
  .genericPolicyPageBanner .main-wrapper {
    position: relative;
    width: 100%;
    height: 100%; }
  .genericPolicyPageBanner .link {
    padding-top: 30px; }
    .genericPolicyPageBanner .link a {
      cursor: pointer;
      background-color: #0c3251;
      color: white; }
      .genericPolicyPageBanner .link a:hover {
        color: #0c3251;
        background-color: white; }
  .genericPolicyPageBanner p {
    color: white;
    padding-top: 5px;
    letter-spacing: 0.5px; }
    .genericPolicyPageBanner p a {
      color: white;
      font-weight: 600;
      border-bottom: 2px solid white; }
      .genericPolicyPageBanner p a:hover {
        color: #0c3251;
        border-color: #0c3251;
        transition: .3s;
        text-decoration: none; }
  .genericPolicyPageBanner .overlayText {
    font-family: 'Montserrat', sans-serif !important;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    line-height: 0.70;
    background: linear-gradient(to right, rgba(12, 50, 81, 0.5) 0%, rgba(12, 50, 81, 0.4) 25%, rgba(12, 50, 81, 0.3) 100%);
    opacity: 0.75;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 9vw !important; }
    @media (min-width: 1800px) {
      .genericPolicyPageBanner .overlayText {
        font-size: 8vw !important; } }
  .genericPolicyPageBanner::after, .genericPolicyPageBanner::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }
  .genericPolicyPageBanner::after {
    background-color: black;
    opacity: 0.5;
    z-index: 0; }
  .genericPolicyPageBanner::before {
    z-index: 1;
    background-color: #8baac2;
    opacity: 0.75; }
  .genericPolicyPageBanner .text {
    padding: 20px;
    position: absolute;
    left: 0;
    z-index: 2;
    width: 100%;
    top: 50%;
    transform: translateY(-50%); }
    .genericPolicyPageBanner .text h1 {
      color: white;
      font-weight: 400; }

@media (max-width: 767px) {
  .genericPolicyPageBanner {
    height: auto !important; }
    .genericPolicyPageBanner .main-wrapper {
      height: auto !important; }
    .genericPolicyPageBanner .text {
      position: relative;
      top: 0;
      transform: translateY(0);
      padding: 50px 20px; }
      .genericPolicyPageBanner .text h1 {
        font-size: 35px !important; } }
/* Contact Page */
.contactUsPage .contactInfo {
  padding: 125px 20px; }
  .contactUsPage .contactInfo h1 {
    font-weight: 400;
    color: #0c3251;
    letter-spacing: 0.5px; }
  .contactUsPage .contactInfo h4 {
    font-weight: 400;
    color: black;
    letter-spacing: 0.5px; }
  .contactUsPage .contactInfo .infoBlock {
    padding-top: 50px; }
    .contactUsPage .contactInfo .infoBlock h6 {
      color: #0c3251;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 500;
      padding-bottom: 10px; }
    .contactUsPage .contactInfo .infoBlock p {
      letter-spacing: 0.5px;
      color: black;
      font-weight: 300; }
      .contactUsPage .contactInfo .infoBlock p strong {
        font-weight: 600; }
      .contactUsPage .contactInfo .infoBlock p a {
        letter-spacing: 0.5px;
        color: black;
        font-weight: 300; }
        .contactUsPage .contactInfo .infoBlock p a:hover {
          color: #0c3251;
          text-decoration: none; }
      .contactUsPage .contactInfo .infoBlock p em {
        font-style: normal;
        font-weight: 400;
        color: #8baac2;
        text-transform: uppercase; }
.contactUsPage .map {
  padding: 100px 100px 100px 0; }
  .contactUsPage .map .gmnoprint {
    display: none !important; }
  .contactUsPage .map #map_canvas {
    position: relative;
    top: -100px;
    width: 100%;
    height: 550px; }
  .contactUsPage .map .gm-style-iw {
    text-align: center;
    padding: 25px 0 15px 15px !important; }
    .contactUsPage .map .gm-style-iw h4 {
      padding-bottom: 10px;
      font-weight: 600;
      font-size: 20px !important;
      color: #0c3251; }
    .contactUsPage .map .gm-style-iw p {
      color: #0c3251;
      font-weight: 400;
      font-size: 14px !important; }
    .contactUsPage .map .gm-style-iw button img[src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22/%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%3C/svg%3E"] {
      display: none !important; }
  .contactUsPage .map .map_main_block {
    padding: 0 100px 0 0;
    background-color: #0c3251; }

@media (max-width: 991px) {
  .contactUsPage .contactInfo {
    padding: 50px 20px;
    text-align: center; }
    .contactUsPage .contactInfo .infoBlock .two {
      padding: 50px 0; }
  .contactUsPage .map {
    padding: 50px 0 0 0; }
    .contactUsPage .map .map_main_block {
      padding: 0 50px 0 0; }
    .contactUsPage .map #map_canvas {
      top: -50px; } }
@media (max-width: 767px) {
  .contactUsPage .map {
    padding: 25px 0 0 0; }
    .contactUsPage .map .map_main_block {
      padding: 0 25px 0 0; }
    .contactUsPage .map #map_canvas {
      top: -25px; } }
/* About Us Page */
.aboutUsPage h1 {
  font-weight: 400; }
.aboutUsPage p {
  letter-spacing: 0.5px; }
  .aboutUsPage p a {
    font-weight: 600;
    border-bottom: none; }
    .aboutUsPage p a:hover {
      text-decoration: none;
      transition: .3s; }
.aboutUsPage .qualityContent {
  position: relative;
  padding-bottom: 150px; }
  .aboutUsPage .qualityContent h1 span {
    text-transform: uppercase;
    color: #dddee0;
    font-weight: 600;
    font-size: 225px !important;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.5; }
  .aboutUsPage .qualityContent .left {
    position: relative;
    z-index: 1;
    padding: 0 100px 0 20px !important; }
    .aboutUsPage .qualityContent .left p {
      color: black;
      padding-bottom: 15px;
      line-height: 1.75;
      max-width: 500px;
      margin: 0 0 0 auto !important; }
      .aboutUsPage .qualityContent .left p strong {
        color: #0c3251; }
  .aboutUsPage .qualityContent .right .empty {
    width: 100%;
    height: 75px;
    background-color: #0c3251; }
.aboutUsPage .GoalBlock {
  text-align: center;
  padding: 150px 20px; }
  .aboutUsPage .GoalBlock p {
    color: black;
    width: fit-content;
    margin: 0 auto !important;
    text-transform: uppercase;
    font-size: 20px !important;
    font-weight: 500;
    letter-spacing: 1px;
    border-bottom: 2px solid black; }
  .aboutUsPage .GoalBlock h1 {
    color: #0c3251;
    padding-top: 25px;
    max-width: 1200px;
    margin: 0 auto !important; }
.aboutUsPage .banner {
  text-align: center;
  position: relative;
  background-color: #8baac2; }
  .aboutUsPage .banner .img_main {
    max-width: 590px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
  .aboutUsPage .banner h1 {
    max-width: 700px;
    margin: 0 auto !important;
    color: white; }
  .aboutUsPage .banner p {
    max-width: 700px;
    margin: 0 auto !important;
    padding-top: 20px;
    color: white;
    line-height: 1.75; }
  .aboutUsPage .banner .left {
    border-right: 375px solid #dddee0;
    padding: 125px 0 !important; }
    .aboutUsPage .banner .left .banner_content_block {
      position: relative;
      z-index: 1;
      padding: 125px 0 !important;
      border-left: 35px solid #dddee0;
      border-right: 35px solid transparent; }
      .aboutUsPage .banner .left .banner_content_block .main_content {
        padding: 0 20px !important; }
.aboutUsPage .contactUsLinkBlock {
  text-align: center;
  padding: 100px 20px; }
  .aboutUsPage .contactUsLinkBlock h1 {
    padding-top: 20px;
    color: #0c3251; }
  .aboutUsPage .contactUsLinkBlock p {
    max-width: 600px;
    padding: 20px 0 50px;
    margin: 0 auto !important;
    color: black; }
    .aboutUsPage .contactUsLinkBlock p a {
      color: #0c3251; }
      .aboutUsPage .contactUsLinkBlock p a:hover {
        color: black;
        border-bottom: 1px solid black; }
  .aboutUsPage .contactUsLinkBlock .link {
    padding-bottom: 15px; }
    .aboutUsPage .contactUsLinkBlock .link a {
      background-color: #0c3251;
      color: white; }
      .aboutUsPage .contactUsLinkBlock .link a:hover {
        background-color: #8baac2;
        color: white; }

@media (max-width: 1150px) {
  .aboutUsPage .banner .left {
    padding: 75px 0 !important; } }
@media (max-width: 1100px) {
  .aboutUsPage .qualityContent {
    padding-bottom: 0; }
    .aboutUsPage .qualityContent h1 span {
      font-size: 135px !important; } }
@media (max-width: 991px) {
  .aboutUsPage .banner .img_main {
    display: none; }
  .aboutUsPage .banner .left {
    border-width: 0; }
    .aboutUsPage .banner .left .banner_content_block {
      border-color: #dddee0 !important;
      border-width: 20px !important;
      padding: 50px 0 !important; }
  .aboutUsPage .qualityContent .left {
    padding: 0 20px !important; }
    .aboutUsPage .qualityContent .left p {
      max-width: 100%;
      text-align: center; }
  .aboutUsPage .qualityContent .right .empty {
    height: 100px; }
  .aboutUsPage .qualityContent h1 span {
    font-size: 85px !important; }
  .aboutUsPage .GoalBlock {
    padding: 75px 20px; }
    .aboutUsPage .GoalBlock h1 {
      font-size: 35px !important; } }
@media (max-width: 767px) {
  .aboutUsPage h1 {
    font-size: 40px !important; }
  .aboutUsPage .qualityContent .row {
    padding-bottom: 50px; }
  .aboutUsPage .qualityContent .right .empty {
    display: none; }
  .aboutUsPage .qualityContent h1 {
    position: relative;
    text-align: center; }
    .aboutUsPage .qualityContent h1 span {
      font-size: 70px !important;
      bottom: 10px;
      position: relative;
      bottom: 0;
      background-color: transparent;
      z-index: 1; }
    .aboutUsPage .qualityContent h1::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #0c3251; }
  .aboutUsPage .contactUsLinkBlock {
    padding: 75px 20px; } }
/* Generic Policy Page */
.genericPolicyPage h1, .genericPolicyPage h2, .genericPolicyPage h3, .genericPolicyPage h4, .genericPolicyPage h5, .genericPolicyPage h6, .genericPolicyPage p, .genericPolicyPage a {
  letter-spacing: 0.5px; }
.genericPolicyPage h1, .genericPolicyPage h2, .genericPolicyPage h3, .genericPolicyPage h4, .genericPolicyPage h5, .genericPolicyPage h6 {
  line-height: 1.25;
  color: #8baac2;
  font-weight: 600;
  padding-top: 25px; }
.genericPolicyPage p, .genericPolicyPage a {
  line-height: 1.75; }
.genericPolicyPage .content {
  padding: 100px 20px; }
  .genericPolicyPage .content .blue_text {
    line-height: 1.25;
    font-size: 20px !important;
    font-family: 'Lexend', sans-serif !important;
    font-weight: 400;
    padding-bottom: 20px;
    color: #8baac2; }
  .genericPolicyPage .content ul, .genericPolicyPage .content ol, .genericPolicyPage .content li {
    font-family: 'Manrope', sans-serif !important; }
  .genericPolicyPage .content ul li {
    list-style: disc;
    color: black; }
    .genericPolicyPage .content ul li strong {
      line-height: 1.25;
      font-size: 20px;
      color: #0c3251; }
  .genericPolicyPage .content ul, .genericPolicyPage .content ol {
    padding-left: 20px; }
  .genericPolicyPage .content .title {
    color: #0c3251; }
  .genericPolicyPage .content a {
    font-weight: 600;
    color: #0c3251;
    border-bottom: 1px solid #0c3251; }
    .genericPolicyPage .content a:hover {
      color: black;
      text-decoration: none;
      transition: .3s;
      border-color: black; }
  .genericPolicyPage .content P {
    color: black;
    padding: 10px 0 !important; }
    .genericPolicyPage .content P strong {
      line-height: 1.25;
      font-size: 20px;
      color: #0c3251; }

@media (max-width: 767px) {
  .genericPolicyPage .content {
    padding: 25px 20px 30px; }
    .genericPolicyPage .content .title {
      display: none; } }
/* Challenge Container */
.shopify-challenge__container {
  max-width: 100% !important;
  font-family: 'Manrope', sans-serif !important;
  padding: 100px 20px; }
  .shopify-challenge__container .shopify-challenge__message {
    color: black;
    font-size: 25px;
    padding-bottom: 10px; }
  .shopify-challenge__container .g-recaptcha {
    margin-bottom: 25px !important; }
  .shopify-challenge__container .shopify-challenge__error {
    font-weight: 400px;
    color: #0c3251; }
  .shopify-challenge__container input[type="submit"] {
    color: white !important;
    font-size: 14px;
    border: none;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #0c3251;
    padding: 17px 30px 15px 30px;
    text-transform: uppercase;
    border-radius: 50px; }
    .shopify-challenge__container input[type="submit"]:hover {
      transition: .3s;
      background-color: #8baac2;
      text-decoration: none; }

/* All Account Pages */
*:focus {
  outline: none !important;
  box-shadow: none !important; }

.single-order-page {
  padding: 50px 20px; }
  .single-order-page h4, .single-order-page h5, .single-order-page span {
    font-weight: 400;
    color: #0c3251;
    letter-spacing: 0.5px; }
  .single-order-page p {
    color: black; }
  .single-order-page .orderdet .order-table {
    border-color: #dddee0;
    margin-bottom: 0; }
    .single-order-page .orderdet .order-table tfoot,
    .single-order-page .orderdet .order-table tfoot tr:last-child th,
    .single-order-page .orderdet .order-table tfoot tr:last-child td,
    .single-order-page .orderdet .order-table tbody tr + tr {
      border-top-color: #dddee0; }
    .single-order-page .orderdet .order-table td {
      font-family: 'Manrope', sans-serif !important; }
    .single-order-page .orderdet .order-table thead {
      border-bottom-color: #dddee0; }
    .single-order-page .orderdet .order-table a {
      color: #8baac2;
      border-bottom: none;
      font-family: 'Manrope', sans-serif !important; }
      .single-order-page .orderdet .order-table a:hover {
        color: #0c3251;
        text-decoration: none; }
    .single-order-page .orderdet .order-table dd, .single-order-page .orderdet .order-table dl {
      font-family: 'Manrope', sans-serif !important;
      margin-bottom: 0 !important; }
    .single-order-page .orderdet .order-table tr, .single-order-page .orderdet .order-table td, .single-order-page .orderdet .order-table th {
      padding: 10px !important; }
    .single-order-page .orderdet .order-table th {
      font-family: 'Lexend', sans-serif !important;
      font-weight: 600;
      color: #0c3251;
      text-transform: capitalize;
      letter-spacing: 0.5px; }
  .single-order-page .addresses {
    padding-left: 25px !important; }
    .single-order-page .addresses .billing {
      padding-bottom: 30px; }

@media (max-width: 767px) {
  .single-order-page h4 {
    padding-bottom: 10px; }
  .single-order-page .addresses {
    text-align: center;
    padding-left: 0 !important;
    padding-top: 35px !important; } }
@media (max-width: 749px) {
  .single-order-page .orderdet .order-table {
    border: 1px solid #dddee0; }
    .single-order-page .orderdet .order-table td {
      padding: 5px 10px !important; }
    .single-order-page .orderdet .order-table th, .single-order-page .orderdet .order-table td:before {
      font-weight: 400 !important;
      letter-spacing: 0.5px; } }
.mainaccountpage {
  padding: 50px 20px; }
  .mainaccountpage .order-history h4 {
    color: #0c3251;
    font-size: 22px;
    letter-spacing: 0.5px;
    padding-bottom: 20px;
    padding-top: 50px;
    font-weight: 400; }
  .mainaccountpage .order-history p {
    letter-spacing: 0.5px; }
  .mainaccountpage .order-history td {
    font-family: 'Manrope', sans-serif !important;
    border-color: #8baac2; }
  .mainaccountpage .order-history th {
    font-family: 'Lexend', sans-serif !important;
    font-weight: 500;
    color: #0c3251;
    border-color: #8baac2; }
    .mainaccountpage .order-history th a {
      border-radius: 50px;
      border: none;
      background: #0c3251;
      color: white;
      font-size: 12px !important; }
      .mainaccountpage .order-history th a:hover {
        color: white;
        background: #8baac2; }
  .mainaccountpage .Address-details h4 {
    color: #0c3251;
    font-size: 22px;
    letter-spacing: 0.5px;
    padding-bottom: 20px;
    padding-top: 50px;
    font-weight: 400; }
  .mainaccountpage .Address-details p {
    color: black;
    padding-bottom: 20px; }
  .mainaccountpage .Address-details .btn {
    color: white !important;
    font-size: 14px !important;
    border: none;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #0c3251;
    padding: 15px 30px;
    text-transform: uppercase;
    border-radius: 50px; }
    .mainaccountpage .Address-details .btn:hover {
      transition: .3s;
      background-color: #8baac2;
      text-decoration: none; }

.mform {
  font-family: 'Manrope', sans-serif !important; }
  .mform .form-heading {
    text-align: center; }
  .mform form {
    width: 100%; }
  .mform .formrowbutton {
    text-align: center;
    padding-top: 20px; }
  .mform .links {
    padding-top: 20px; }
  .mform select {
    margin: 5px 0;
    padding: 15px;
    text-align-last: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-radius: 0;
    width: 100%;
    border: 1px solid #dddee0; }
  .mform input[type="text"],
  .mform input[type="email"],
  .mform input[type="password"],
  .mform input[type="tel"] {
    color: black;
    border: 1px solid #dddee0;
    width: 100%;
    font-size: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 0;
    padding: 15px 10px;
    text-align: center;
    margin: 5px 0; }
    .mform input[type="text"]::placeholder,
    .mform input[type="email"]::placeholder,
    .mform input[type="password"]::placeholder,
    .mform input[type="tel"]::placeholder {
      color: #252525;
      opacity: 1; }
    .mform input[type="text"]:focus,
    .mform input[type="email"]:focus,
    .mform input[type="password"]:focus,
    .mform input[type="tel"]:focus {
      border-color: black !important; }
  .mform input[type="submit"] {
    color: white !important;
    font-size: 14px;
    border: none;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #0c3251;
    padding: 17px 30px 15px 30px;
    text-transform: uppercase;
    border-radius: 50px; }
    .mform input[type="submit"]:hover {
      transition: .3s;
      background-color: #8baac2;
      text-decoration: none; }

.accountpage .alladdress {
  max-width: 1425px;
  margin: 0 auto; }
  .accountpage .alladdress .row {
    justify-content: center; }
  .accountpage .alladdress .alladdress-single {
    text-align: center;
    padding: 50px 20px !important; }
    .accountpage .alladdress .alladdress-single h6 {
      color: #0c3251;
      font-weight: 400;
      padding-bottom: 10px; }
    .accountpage .alladdress .alladdress-single .list--inline {
      padding-top: 10px; }
      .accountpage .alladdress .alladdress-single .list--inline li button {
        border: none;
        border-radius: 50px;
        padding: 5px 20px;
        background-color: #0c3251;
        color: white;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px; }
        .accountpage .alladdress .alladdress-single .list--inline li button:hover {
          transition: .3s;
          background-color: #8baac2; }
.accountpage .addressform {
  display: none;
  padding: 50px 20px; }
.accountpage .edit-address-form form {
  padding: 50px 20px; }
.accountpage .edit-address-form h4,
.accountpage .addressform h4 {
  font-weight: 400;
  color: black;
  padding-bottom: 20px; }
.accountpage .edit-address-form form,
.accountpage .addressform form {
  max-width: 600px;
  margin: 0 auto; }
  .accountpage .edit-address-form form .leftrow,
  .accountpage .addressform form .leftrow {
    padding-right: 5px !important; }
  .accountpage .edit-address-form form .rightrow,
  .accountpage .addressform form .rightrow {
    padding-left: 5px !important; }
.accountpage .edit-address-form .checkboxblock,
.accountpage .addressform .checkboxblock {
  padding-top: 10px;
  color: black;
  letter-spacing: 0.5px;
  text-align: center; }
  .accountpage .edit-address-form .checkboxblock input,
  .accountpage .addressform .checkboxblock input {
    width: 25px;
    height: 20px;
    position: relative;
    top: 2.5px; }
.accountpage .edit-address-form .closebtn,
.accountpage .addressform .closebtn {
  padding-top: 25px;
  text-align: center; }
  .accountpage .edit-address-form .closebtn button,
  .accountpage .addressform .closebtn button {
    padding: 0 !important;
    color: #0c3251;
    font-weight: 600; }
    .accountpage .edit-address-form .closebtn button:hover,
    .accountpage .addressform .closebtn button:hover {
      text-decoration: none;
      color: #8baac2; }
.accountpage hr {
  margin: 0 !important; }

@media (max-width: 767px) {
  .accountpage .alladdress .alladdress-single {
    padding: 15px 20px !important; }
  .accountpage .alladdress .alladdress-single:first-child {
    padding: 25px 20px 15px !important; }
  .accountpage .alladdress .alladdress-single:last-child {
    padding: 15px 20px 25px 20px !important; } }
.all-account-page .form-message--success {
  margin-bottom: 0;
  margin-top: 25px;
  max-width: 650px;
  margin: 50px auto 0 auto;
  text-align: center;
  border: none;
  background-color: #0c3251;
  color: white;
  font-size: 17px; }
.all-account-page .resetpass,
.all-account-page .loginblock,
.all-account-page .resetblock,
.all-account-page .createaccount {
  max-width: 700px;
  margin: 0 auto !important;
  padding: 50px 20px; }
  .all-account-page .resetpass .errors,
  .all-account-page .loginblock .errors,
  .all-account-page .resetblock .errors,
  .all-account-page .createaccount .errors {
    padding: 10px;
    text-align: center;
    margin-bottom: 20px;
    background-color: #0c3251;
    color: white; }
  .all-account-page .resetpass .links,
  .all-account-page .loginblock .links,
  .all-account-page .resetblock .links,
  .all-account-page .createaccount .links {
    text-align: center; }
    .all-account-page .resetpass .links p,
    .all-account-page .loginblock .links p,
    .all-account-page .resetblock .links p,
    .all-account-page .createaccount .links p {
      padding: 5px; }
      .all-account-page .resetpass .links p a,
      .all-account-page .loginblock .links p a,
      .all-account-page .resetblock .links p a,
      .all-account-page .createaccount .links p a {
        padding: 0 !important;
        color: #0c3251;
        font-weight: 600; }
        .all-account-page .resetpass .links p a:hover,
        .all-account-page .loginblock .links p a:hover,
        .all-account-page .resetblock .links p a:hover,
        .all-account-page .createaccount .links p a:hover {
          text-decoration: none;
          color: #8baac2; }
  .all-account-page .resetpass .form-heading,
  .all-account-page .loginblock .form-heading,
  .all-account-page .resetblock .form-heading,
  .all-account-page .createaccount .form-heading {
    padding-bottom: 20px; }
    .all-account-page .resetpass .form-heading h3,
    .all-account-page .loginblock .form-heading h3,
    .all-account-page .resetblock .form-heading h3,
    .all-account-page .createaccount .form-heading h3 {
      color: #0c3251;
      font-weight: 400; }
    .all-account-page .resetpass .form-heading p,
    .all-account-page .loginblock .form-heading p,
    .all-account-page .resetblock .form-heading p,
    .all-account-page .createaccount .form-heading p {
      color: black;
      letter-spacing: 0.5px; }
.all-account-page #RecoverPasswordForm {
  display: none; }

@media (max-width: 749px) {
  .mainaccountpage .Address-details {
    text-align: center; }
    .mainaccountpage .Address-details h4 {
      padding-top: 25px; }
  .mainaccountpage .order-history h4 {
    padding-top: 0; }
  .mainaccountpage .order-history tr {
    border: 1px solid #dddee0; }
    .mainaccountpage .order-history tr th {
      padding: 10px;
      border-bottom: 1px solid #dddee0; }
    .mainaccountpage .order-history tr th[data-label="Order"]:before {
      padding-top: 2.5px;
      font-size: 17px; }
    .mainaccountpage .order-history tr td {
      padding: 6px 10px; }
      .mainaccountpage .order-history tr td::before {
        font-size: 15px; }
      .mainaccountpage .order-history tr td time {
        font-size: 15px; } }
@media (max-width: 576px) {
  .accountpage .accountpage form .leftrow,
  .accountpage .edit-address-form form .leftrow {
    padding-right: 0 !important; }
  .accountpage .accountpage form .rightrow,
  .accountpage .edit-address-form form .rightrow {
    padding-left: 0 !important; } }
/* Footer */
.FooterMain {
  background-color: #252525;
  padding: 75px 20px 50px; }
  .FooterMain h5 {
    color: white;
    font-weight: 500;
    letter-spacing: 0.5px; }
  .FooterMain ul {
    padding-top: 10px;
    padding-left: 0;
    margin-bottom: 0; }
    .FooterMain ul li {
      list-style: none; }
      .FooterMain ul li a {
        color: white;
        font-weight: 300;
        letter-spacing: 0.5px; }
        .FooterMain ul li a:hover {
          text-decoration: none;
          color: #8baac2;
          transition: .3s; }
  .FooterMain .form-message {
    background-color: transparent;
    border: none;
    padding: 0 0 20px 0;
    color: #8baac2;
    letter-spacing: 0.5px;
    font-weight: 700; }
  .FooterMain form {
    padding-top: 10px; }
    .FooterMain form section {
      display: block;
      margin: 0 !important; }
      .FooterMain form section .form-group {
        position: relative; }
        .FooterMain form section .form-group input[type="email"] {
          width: 100%;
          padding: 8px 15px !important;
          border: 2px solid white;
          border-radius: 50px;
          height: auto;
          letter-spacing: 0.5px;
          color: #252525; }
          .FooterMain form section .form-group input[type="email"]::placeholder {
            color: #252525; }
        .FooterMain form section .form-group button {
          height: auto;
          min-height: auto;
          position: absolute;
          right: 1.5px;
          top: 1px;
          border: 2px solid white;
          border-radius: 50px;
          width: fit-content;
          background-color: #8baac2;
          color: white;
          text-transform: uppercase;
          font-size: 12px;
          width: auto; }
          .FooterMain form section .form-group button:hover {
            background-color: #0c3251; }
  .FooterMain .footerCopyright {
    padding-top: 75px; }
    .FooterMain .footerCopyright .left {
      display: flex; }
      .FooterMain .footerCopyright .left ul {
        padding-top: 0;
        padding-right: 20px; }
        .FooterMain .footerCopyright .left ul li {
          padding: 0 5px 0 0;
          display: inline; }
          .FooterMain .footerCopyright .left ul li a {
            color: white; }
            .FooterMain .footerCopyright .left ul li a:hover {
              color: #8baac2 !important; }
      .FooterMain .footerCopyright .left p, .FooterMain .footerCopyright .left a {
        align-self: center;
        color: #585858;
        font-size: 14px !important;
        letter-spacing: 0.5px; }
      .FooterMain .footerCopyright .left a:hover {
        transition: .3s;
        color: white !important;
        text-decoration: none; }
    .FooterMain .footerCopyright .right {
      align-self: flex-end;
      text-align: right; }
      .FooterMain .footerCopyright .right img {
        max-width: 350px;
        width: 100%; }

@media (max-width: 991px) {
  .FooterMain {
    text-align: center; }
    .FooterMain .block_2 {
      padding: 50px 0; }
    .FooterMain .block_4 {
      padding: 50px 0 0 0;
      max-width: 400px;
      margin: 0 auto; }
    .FooterMain .footerCopyright .left {
      order: 2;
      display: block; }
      .FooterMain .footerCopyright .left ul {
        padding-right: 0;
        padding-bottom: 45px; }
    .FooterMain .footerCopyright .right {
      text-align: center;
      padding-bottom: 45px;
      order: 1; } }
@media (max-width: 749px) {
  .FooterMain form section .form-group input[type=email] {
    padding: 6px 15px !important; } }
/* Homepage Collection Block */
.homepage-collection-block {
  padding: 100px 0; }
  .homepage-collection-block .main-wrapper {
    max-width: 1200px; }
    .homepage-collection-block .main-wrapper .single-collection-1 .link a {
      background-color: #0c3251; }
      .homepage-collection-block .main-wrapper .single-collection-1 .link a:hover {
        background-color: #8baac2; }
    .homepage-collection-block .main-wrapper .single-collection-2 .link a {
      background-color: #8baac2; }
      .homepage-collection-block .main-wrapper .single-collection-2 .link a:hover {
        background-color: #0c3251; }
    .homepage-collection-block .main-wrapper .single-collection {
      padding: 25px !important; }
      .homepage-collection-block .main-wrapper .single-collection .link {
        padding: 35px 0 15px 0; }
        .homepage-collection-block .main-wrapper .single-collection .link a {
          color: white; }
          .homepage-collection-block .main-wrapper .single-collection .link a:hover {
            transition: .3s;
            color: white; }
      .homepage-collection-block .main-wrapper .single-collection img {
        width: 100%; }
      .homepage-collection-block .main-wrapper .single-collection h2 {
        color: black;
        font-weight: 400;
        letter-spacing: 0.5px;
        padding-top: 20px;
        padding-bottom: 10px;
        text-transform: initial; }
      .homepage-collection-block .main-wrapper .single-collection p {
        color: black;
        letter-spacing: 0.5px;
        line-height: 1.5; }

@media (max-width: 991px) {
  .homepage-collection-block {
    padding: 0 10px; }
    .homepage-collection-block .main-wrapper .single-collection {
      padding: 25px 10px !important; } }
@media (max-width: 767px) {
  .homepage-collection-block {
    text-align: center;
    padding: 10px; }
    .homepage-collection-block .main-wrapper .single-collection {
      padding: 15px 10px !important; } }
/* Homepage Mission Block */
.homepage-mission-block {
  padding: 100px 20px;
  text-align: center; }
  .homepage-mission-block .main-wrapper {
    max-width: 800px; }
  .homepage-mission-block h1 {
    color: #0c3251;
    font-weight: 400;
    padding: 35px 0 0 0; }
  .homepage-mission-block .link {
    padding: 35px 0 15px; }
    .homepage-mission-block .link a {
      border: 1px solid #0c3251;
      background-color: white;
      color: #0c3251; }
      .homepage-mission-block .link a:hover {
        color: white;
        background-color: #0c3251; }
  .homepage-mission-block p {
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px; }

@media (max-width: 767px) {
  .homepage-mission-block {
    padding: 75px 20px; }
    .homepage-mission-block h1 {
      font-size: 35px !important;
      padding: 25px 0 5px; } }
/* Homepage Category Block */
.homepage-category-block {
  background-color: #8baac2;
  padding: 50px 5px; }
  .homepage-category-block .mobile {
    display: none;
    text-align: center;
    text-transform: uppercase;
    color: #0c3251;
    font-size: 22px !important;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 1.25;
    padding-bottom: 25px; }
  .homepage-category-block .main-wrapper {
    max-width: 1425px; }
  .homepage-category-block .single-category {
    padding: 15px !important; }
    .homepage-category-block .single-category p {
      text-transform: uppercase;
      color: #0c3251;
      font-size: 22px !important;
      letter-spacing: 1px;
      font-weight: 600;
      max-width: 125px;
      line-height: 1.25;
      padding-bottom: 25px; }
    .homepage-category-block .single-category .links {
      text-align: center; }
      .homepage-category-block .single-category .links a {
        text-transform: uppercase;
        color: white;
        letter-spacing: 1px;
        font-weight: 400; }
        .homepage-category-block .single-category .links a:hover {
          color: #0c3251;
          text-decoration: none;
          transition: .3s; }
    .homepage-category-block .single-category h4 {
      text-align: center;
      color: white;
      font-weight: 400;
      padding-bottom: 10px; }
  .homepage-category-block .single-category-1 {
    padding-top: 75px !important; }
    .homepage-category-block .single-category-1 h4 {
      padding-top: 15px !important; }
    .homepage-category-block .single-category-1 .links {
      padding-bottom: 15px !important; }
  .homepage-category-block .single-category-2 .links {
    padding-bottom: 15px !important; }
  .homepage-category-block .single-category-3 {
    padding-top: 150px !important; }
    .homepage-category-block .single-category-3 h4 {
      padding-top: 15px !important; }
  .homepage-category-block .single-category-4 {
    padding-top: 100px !important; }
    .homepage-category-block .single-category-4 h4 {
      padding-top: 15px !important; }

@media (max-width: 991px) {
  .homepage-category-block {
    padding: 30px 10px 25px 10px; }
    .homepage-category-block .mobile {
      padding-top: 10px !important;
      display: block; }
    .homepage-category-block .desktop {
      display: none; }
    .homepage-category-block .single-category {
      padding: 10px !important; }
      .homepage-category-block .single-category h4 {
        padding-bottom: 0 !important; }
    .homepage-category-block .single-category-1 {
      padding-bottom: 70px !important; }
      .homepage-category-block .single-category-1 .links {
        padding-bottom: 10px !important; }
    .homepage-category-block .single-category-2 {
      align-self: flex-end; }
    .homepage-category-block .single-category-3 {
      padding-top: 70px !important; }
    .homepage-category-block .single-category-4 {
      padding-top: 10px !important; } }
@media (max-width: 767px) {
  .homepage-category-block .single-category {
    flex: 0 0 50%;
    max-width: 50%; }
    .homepage-category-block .single-category h4 {
      font-size: 20px !important; }
    .homepage-category-block .single-category .links a {
      font-size: 14px !important; } }
/* Homepage Bestsellers Block */
.homepage-bestsellers-block {
  padding: 100px 50px; }
  .homepage-bestsellers-block .owl-prev {
    position: absolute;
    left: -30px;
    top: 40%;
    transform: translateY(-50%); }
    .homepage-bestsellers-block .owl-prev i {
      font-size: 40px;
      color: #0c3251; }
  .homepage-bestsellers-block .owl-next {
    position: absolute;
    right: -30px;
    top: 40%;
    transform: translateY(-50%); }
    .homepage-bestsellers-block .owl-next i {
      font-size: 40px;
      color: #0c3251; }
  .homepage-bestsellers-block .bestsellers-category-sliders #women {
    display: none; }
  .homepage-bestsellers-block .title-block {
    display: flex;
    padding: 0 20px 25px 20px; }
    .homepage-bestsellers-block .title-block h2 {
      width: 60%;
      font-weight: 500;
      color: #0c3251;
      letter-spacing: 0.5px; }
    .homepage-bestsellers-block .title-block div {
      width: 40%;
      text-align: right;
      align-self: center; }
      .homepage-bestsellers-block .title-block div ul li {
        cursor: pointer;
        letter-spacing: 1px;
        color: #939598;
        display: inline-block;
        text-transform: uppercase;
        padding-left: 10px;
        font-family: 'Lexend', sans-serif !important;
        font-weight: 500; }
        .homepage-bestsellers-block .title-block div ul li:first-child {
          padding-right: 10px;
          border-right: 2px solid #939598; }
        .homepage-bestsellers-block .title-block div ul li.active {
          color: #0c3251; }
  @media (max-width: 767px) {
    .homepage-bestsellers-block .images .product-images-single > img, .homepage-bestsellers-block .images .product-images-single {
      height: auto !important; } }

@media (max-width: 767px) {
  .homepage-bestsellers-block {
    padding: 50px 0px; }
    .homepage-bestsellers-block .owl-prev {
      left: -15px; }
      .homepage-bestsellers-block .owl-prev i {
        font-size: 30px; }
    .homepage-bestsellers-block .owl-next {
      right: -15px; }
      .homepage-bestsellers-block .owl-next i {
        font-size: 30px; }
    .homepage-bestsellers-block .title-block {
      display: block; }
      .homepage-bestsellers-block .title-block h2 {
        width: 100%;
        text-align: center;
        padding-bottom: 20px; }
      .homepage-bestsellers-block .title-block div {
        width: 100%; }
        .homepage-bestsellers-block .title-block div ul {
          text-align: center; }
    .homepage-bestsellers-block .bestsellers-category-sliders {
      padding: 0 25px; } }
/* Homepage Featured Block */
.homepage-featured-block {
  background-color: #8baac2;
  overflow: hidden; }
  .homepage-featured-block .text-block .title {
    color: #0c3251;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    max-width: 133px;
    text-align: left !important; }
  .homepage-featured-block .text-block .price {
    color: white;
    font-size: 20px !important;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px; }
  .homepage-featured-block .one {
    background: linear-gradient(to right, #dddee0 0%, #dddee0 45%, #8baac2 45%, #8baac2 100%);
    position: relative; }
    .homepage-featured-block .one img {
      max-width: 400px;
      width: 100%;
      position: absolute;
      left: -25px;
      bottom: -25px; }
    .homepage-featured-block .one .text-block {
      position: absolute;
      right: -50px;
      top: 50px;
      z-index: 1; }
  .homepage-featured-block .two {
    text-align: center;
    background-color: #8baac2;
    padding: 200px 20px !important; }
    .homepage-featured-block .two h1 {
      max-width: 550px;
      margin: 0 auto !important;
      font-weight: 400;
      color: white; }
    .homepage-featured-block .two p {
      max-width: 600px;
      margin: 0 auto !important;
      font-size: 18px;
      letter-spacing: 0.5px;
      font-weight: 300;
      padding-top: 10px;
      color: white;
      line-height: 1.75; }
    .homepage-featured-block .two .link {
      padding: 35px 0 15px 0; }
      .homepage-featured-block .two .link br {
        display: none; }
      .homepage-featured-block .two .link .link1 {
        border: 1px solid white;
        color: white; }
        .homepage-featured-block .two .link .link1:hover {
          background-color: white;
          color: #0c3251;
          transition: .3s; }
      .homepage-featured-block .two .link .link2 {
        border: 1px solid #0c3251;
        background-color: #0c3251;
        color: white; }
        .homepage-featured-block .two .link .link2:hover {
          background-color: #8baac2;
          color: #0c3251;
          transition: .3s; }
  .homepage-featured-block .three {
    background-color: #8baac2;
    border-top: 35px solid #dddee0;
    position: relative;
    text-align: right; }
    .homepage-featured-block .three .text-block {
      position: absolute;
      left: -85px;
      bottom: 20px; }
    .homepage-featured-block .three img {
      width: 100%;
      max-width: 375px;
      position: absolute;
      right: 0;
      bottom: 0; }

@media (max-width: 1100px) {
  .homepage-featured-block .one .text-block {
    right: -130px;
    top: auto;
    bottom: 20px; }
  .homepage-featured-block .two {
    padding: 50px 20px 200px 20px !important; }
  .homepage-featured-block .three {
    border-top: 0; }
    .homepage-featured-block .three img {
      max-width: 225px; } }
@media (max-width: 991px) {
  .homepage-featured-block .one {
    flex: 0 0 40%;
    max-width: 40%;
    order: 2;
    height: 400px;
    background: linear-gradient(to right, #dddee0 0%, #dddee0 20%, #8baac2 20%, #8baac2 100%); }
    .homepage-featured-block .one img {
      max-width: 200px; }
    .homepage-featured-block .one .text-block {
      right: auto;
      left: 70px;
      top: 15px;
      bottom: auto; }
  .homepage-featured-block .two {
    order: 1;
    padding: 50px 20px !important; }
  .homepage-featured-block .three {
    flex: 0 0 60%;
    max-width: 60%;
    order: 3;
    height: 400px; }
    .homepage-featured-block .three img {
      max-width: 165px; }
    .homepage-featured-block .three .text-block {
      left: auto;
      bottom: 250px;
      right: 0; } }
@media (max-width: 767px) {
  .homepage-featured-block .one {
    background: linear-gradient(to right, #dddee0 0%, #dddee0 5%, #8baac2 5%, #8baac2 100%); }
    .homepage-featured-block .one .text-block {
      left: 20px; }
  .homepage-featured-block .two .link br {
    display: block; } }
/* HEADER SEARCH BAR */
.search-bar__interior {
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: block; }
  .search-bar__interior button:focus {
    outline: none; }
  .search-bar__interior .btn--link,
  .search-bar__interior .search-bar__submit {
    top: 2px;
    color: black !important;
    background-color: white !important; }
  .search-bar__interior .search-form__container {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 20px; }
    .search-bar__interior .search-form__container .search-bar__form {
      width: 100%;
      max-width: 100%; }
      .search-bar__interior .search-form__container .search-bar__form .search-form__input-wrapper:focus {
        outline: none; }
      .search-bar__interior .search-form__container .search-bar__form .search-bar__input {
        font-family: 'Manrope', sans-serif !important;
        border: none;
        border-radius: 0;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: black;
        border-bottom: 1px solid black; }
        .search-bar__interior .search-form__container .search-bar__form .search-bar__input:focus {
          outline: none; }
  .search-bar__interior .predictive-search-wrapper {
    width: 100%;
    max-width: 100% !important;
    border-color: black; }
  .search-bar__interior .predictive-search-title {
    background-color: #0c3251; }
    .search-bar__interior .predictive-search-title h3 {
      color: white;
      font-size: 20px !important;
      text-transform: inherit; }
  .search-bar__interior .predictive-search-view-all__button {
    font-size: 17px !important;
    font-weight: 400 !important;
    color: #0c3251; }
  .search-bar__interior .predictive-search-item__title-text {
    color: #0c3251 !important;
    font-weight: 400 !important; }

/* Homepage Banner Block */
.homepage-banner-block {
  background-color: #8baac2;
  overflow: hidden; }
  .homepage-banner-block .right-slider {
    width: 100%;
    height: 100%; }
    .homepage-banner-block .right-slider .img {
      width: 100%;
      height: 100%;
      background-size: cover !important;
      background-position: center !important; }
  .homepage-banner-block .left-slider {
    position: absolute;
    left: 25px;
    bottom: -25px;
    max-width: 400px;
    z-index: 2; }
    .homepage-banner-block .left-slider img {
      width: 100%; }
  .homepage-banner-block .content-slider .owl-dots {
    position: absolute;
    width: 100%;
    bottom: -100px; }
    .homepage-banner-block .content-slider .owl-dots button {
      color: unset;
      padding: unset !important;
      font: unset;
      position: relative;
      width: 12px;
      height: 12px;
      border: 1px solid white;
      background: transparent;
      border-radius: 50px;
      margin: 0 3px; }
      .homepage-banner-block .content-slider .owl-dots button.active {
        background: white; }
  .homepage-banner-block .content-slider .single-contentslider {
    padding: 125px 0; }
    .homepage-banner-block .content-slider .single-contentslider .content-main {
      padding: 100px 0;
      border-left: 150px solid #dddee0;
      text-align: center; }
      .homepage-banner-block .content-slider .single-contentslider .content-main h1 {
        max-width: 400px;
        margin: 0 auto !important;
        font-weight: 400;
        color: white; }
      .homepage-banner-block .content-slider .single-contentslider .content-main p {
        max-width: 600px;
        margin: 0 auto !important;
        font-size: 18px;
        letter-spacing: 0.5px;
        font-weight: 300;
        padding-top: 10px;
        color: white;
        line-height: 1.75; }
      .homepage-banner-block .content-slider .single-contentslider .content-main .link {
        padding: 35px 0 15px 0; }
        .homepage-banner-block .content-slider .single-contentslider .content-main .link br {
          display: none; }
        .homepage-banner-block .content-slider .single-contentslider .content-main .link .link1 {
          border: 1px solid white;
          color: white; }
          .homepage-banner-block .content-slider .single-contentslider .content-main .link .link1:hover {
            background-color: white;
            color: #0c3251;
            transition: .3s; }
        .homepage-banner-block .content-slider .single-contentslider .content-main .link .link2 {
          background-color: #0c3251;
          color: white; }
          .homepage-banner-block .content-slider .single-contentslider .content-main .link .link2:hover {
            background-color: white;
            color: #0c3251;
            transition: .3s; }

@media (max-width: 1450px) {
  .homepage-banner-block .content-slider .single-contentslider .content-main .content-main-single {
    width: fit-content;
    margin: 0 50px 0 auto; } }
@media (max-width: 1300px) {
  .homepage-banner-block .content-slider .single-contentslider {
    padding: 75px 0; }
    .homepage-banner-block .content-slider .single-contentslider .content-main .content-main-single {
      width: 100%;
      max-width: 500px; } }
@media (max-width: 1160px) {
  .homepage-banner-block .left-slider {
    left: 0;
    max-width: 300px; }
  .homepage-banner-block .content-slider .single-contentslider .content-main .content-main-single {
    margin: 0 20px 0 auto; } }
@media (max-width: 991px) {
  .homepage-banner-block .left-slider {
    display: none; }
  .homepage-banner-block .right-slider {
    height: 700px; }
  .homepage-banner-block .content-slider .owl-dots {
    bottom: 0;
    left: 0; }
  .homepage-banner-block .content-slider .single-contentslider .content-main {
    padding: 50px 20px !important;
    border-left: 20px solid #dddee0;
    border-right: 20px solid #dddee0; }
    .homepage-banner-block .content-slider .single-contentslider .content-main .content-main-single {
      margin: 0 auto; } }
@media (max-width: 767px) {
  .homepage-banner-block .right-slider {
    height: 300px; }
  .homepage-banner-block .content-slider .single-contentslider .content-main .link br {
    display: block; } }
/* Header */
.site-header {
  padding: 25px 20px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
  .site-header.one {
    padding: 0 !important; }
  .site-header .mainLogo {
    width: fit-content; }
    .site-header .mainLogo h4 {
      font-weight: 600;
      color: #0c3251; }
    .site-header .mainLogo a:hover {
      text-decoration: none;
      color: #0c3251; }
  .site-header .mainMenu {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%); }
    .site-header .mainMenu ul {
      display: inline; }
      .site-header .mainMenu ul li {
        display: inline-block; }
        .site-header .mainMenu ul li a.otherLink {
          padding-right: 20px;
          color: black;
          letter-spacing: 0.5px;
          font-weight: 500; }
          .site-header .mainMenu ul li a.otherLink:hover {
            color: #0c3251;
            text-decoration: none; }
        .site-header .mainMenu ul li.accounticon a {
          padding: 0 10px !important; }
        .site-header .mainMenu ul li.accounticon svg {
          width: 19px !important;
          height: 20px !important;
          position: relative;
          top: -2px; }
        .site-header .mainMenu ul li.searchicon svg {
          margin: 0 !important;
          width: 23px;
          height: 23px;
          position: relative;
          top: -2px;
          fill: black !important; }
        .site-header .mainMenu ul li.searchicon button {
          padding: 0 !important; }
        .site-header .mainMenu ul li.searchicon i {
          font-size: 20px;
          color: #0c3251 !important; }
        .site-header .mainMenu ul li.carticon a {
          padding: 0 !important; }
        .site-header .mainMenu ul li.carticon svg {
          width: 22px !important;
          height: 23px !important;
          position: relative;
          top: -4px; }
        .site-header .mainMenu ul li.bar-icon {
          display: none;
          position: relative;
          z-index: 9999;
          width: 25px;
          height: 30px;
          top: 3px;
          padding: 0 0 0 10px !important; }
          .site-header .mainMenu ul li.bar-icon #nav-icon {
            position: relative;
            height: inherit;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .5s ease-in-out;
            -moz-transition: .5s ease-in-out;
            -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
            cursor: pointer;
            width: inherit; }
            .site-header .mainMenu ul li.bar-icon #nav-icon span {
              display: block;
              position: absolute;
              height: 2px;
              width: 100%;
              background: black;
              border-radius: 2px;
              opacity: 1;
              right: 0;
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
              -webkit-transition: .25s ease-in-out;
              -moz-transition: .25s ease-in-out;
              -o-transition: .25s ease-in-out;
              transition: .25s ease-in-out; }
            .site-header .mainMenu ul li.bar-icon #nav-icon.open {
              top: 5px; }
              .site-header .mainMenu ul li.bar-icon #nav-icon.open span {
                background: black; }
            .site-header .mainMenu ul li.bar-icon #nav-icon span:nth-child(1) {
              top: 8px;
              width: 25px; }
            .site-header .mainMenu ul li.bar-icon #nav-icon.open span:nth-child(1) {
              top: 9px;
              width: 26px;
              -webkit-transform: rotate(135deg);
              -moz-transform: rotate(135deg);
              -o-transform: rotate(135deg);
              transform: rotate(135deg); }
            .site-header .mainMenu ul li.bar-icon #nav-icon span:nth-child(2) {
              top: 15px; }
            .site-header .mainMenu ul li.bar-icon #nav-icon.open span:nth-child(2) {
              opacity: 0;
              right: 50px; }
            .site-header .mainMenu ul li.bar-icon #nav-icon span:nth-child(3) {
              top: 22px;
              width: 25px; }
            .site-header .mainMenu ul li.bar-icon #nav-icon.open span:nth-child(3) {
              width: 26px;
              top: 9px;
              -webkit-transform: rotate(-135deg);
              -moz-transform: rotate(-135deg);
              -o-transform: rotate(-135deg);
              transform: rotate(-135deg); }
        .site-header .mainMenu ul li .site-header__cart-count {
          background: transparent;
          color: #0c3251; }
          .site-header .mainMenu ul li .site-header__cart-count span {
            position: relative;
            top: 0px;
            right: -4px;
            font-weight: 600;
            font-size: 8px !important; }

@media (max-width: 1200px) {
  .site-header .mainMenu ul li a.otherLink {
    padding-right: 15px;
    font-size: 15px !important; } }
@media (max-width: 991px) {
  .site-header .mainMenu {
    right: 30px; }
    .site-header .mainMenu ul li.main_link {
      display: none; }
    .site-header .mainMenu ul li.searchicon, .site-header .mainMenu ul li.accounticon {
      position: relative;
      top: -5px; }
    .site-header .mainMenu ul li.carticon {
      position: relative;
      top: -3px; }
    .site-header .mainMenu ul li.navicon {
      position: relative;
      top: 2px;
      padding-left: 10px; }
    .site-header .mainMenu ul li.bar-icon {
      display: inline-block; } }
.home-mobile-menu {
  background-color: #0c3251;
  display: none; }
  .home-mobile-menu ul {
    padding: 15px 0;
    text-align: center; }
    .home-mobile-menu ul li {
      padding: 5px 0; }
      .home-mobile-menu ul li a {
        letter-spacing: 0.5px;
        color: white; }
        .home-mobile-menu ul li a:hover {
          text-decoration: none;
          color: #8baac2; }

@media (max-width: 749px) {
  .site-header .mainLogo img {
    max-width: 150px !important; }
  .site-header .mainMenu ul li .site-header__cart-count span {
    top: -8px;
    right: 1px; } }
/* Product Page */
.page-container {
  overflow: unset !important; }

.sizetable {
  font-family: 'Lexend', sans-serif !important;
  margin-bottom: 0;
  letter-spacing: 0.5px;
  text-align: center; }
  .sizetable .tr {
    display: flex; }
  .sizetable .td {
    width: 20%;
    padding: 10px; }
  .sizetable .th {
    display: flex;
    background: #0c3251;
    color: white; }
  .sizetable .tr:nth-of-type(odd) {
    background: #ebebeb; }
  .sizetable .tr:nth-of-type(even) {
    background: #dddee0; }
  @media (max-width: 600px), (max-device-width: 600px) {
    .sizetable {
      text-align: center; }
      .sizetable .th {
        display: none; }
      .sizetable .tr {
        display: block;
        margin-bottom: 15px;
        background: transparent !important; }
      .sizetable .td {
        background: #f0f0f0;
        display: flex;
        width: 100%;
        margin: auto;
        padding: 0; }
        .sizetable .td span {
          width: 50%;
          padding: 10px; }
      .sizetable .td:nth-of-type(odd) {
        background: rgba(14, 50, 81, 0.25); }
      .sizetable .td:nth-of-type(even) {
        background: rgba(0, 0, 0, 0.05); }
      .sizetable .td:before {
        font-weight: bold;
        display: block;
        width: 50%;
        padding: 10px; }
      .sizetable .td:nth-of-type(1) {
        background: #0c3251;
        color: white; }
        .sizetable .td:nth-of-type(1):before {
          background: #0c3251;
          color: white; }
      .sizetable .td:nth-of-type(1):before {
        content: "Size"; }
      .sizetable .td:nth-of-type(2):before {
        content: "Chest"; }
      .sizetable .td:nth-of-type(3):before {
        content: "Waist"; }
      .sizetable .td:nth-of-type(4):before {
        content: "Sleeve"; }
      .sizetable .td:nth-of-type(5):before {
        content: "Shoulder"; }
      .sizetable .td:nth-of-type(6):before {
        content: "Back"; } }

body {
  position: relative; }
  body.modal-open::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.25);
    z-index: 10; }
  body.modal-open .modal {
    opacity: 1; }

.modal {
  background: transparent !important; }
  .modal .modal-header {
    border: none !important;
    padding: 0 !important;
    text-align: right;
    width: 100%;
    display: block; }
  .modal .close {
    position: relative;
    padding: 20px 25px 0;
    opacity: 1;
    margin: 0 !important; }
    .modal .close i {
      font-size: 35px; }
  .modal .modal-content {
    border: none !important;
    border-radius: 0 !important; }
  .modal .modal-dialog {
    max-width: 650px !important;
    width: 100% !important;
    margin: 225px auto 25px auto; }
  .modal .modal-body {
    padding: 0 50px 45px; }
    .modal .modal-body h4 {
      text-align: center !important;
      color: #0c3251 !important;
      letter-spacing: 0.5px !important;
      font-weight: 400 !important; }
    .modal .modal-body p {
      padding: 10px 10px 20px !important;
      text-align: center !important;
      font-size: 17px !important;
      letter-spacing: 0.5px !important;
      color: black !important; }

.modal-backdrop {
  z-index: -1 !important; }

@media (max-width: 600px) {
  .modal .modal-dialog {
    margin: 50px auto !important;
    padding: 0 20px !important; }
  .modal .modal-body {
    padding: 15px 15px 2px; } }
.single-product-other-info .left {
  align-self: center;
  text-align: center;
  padding: 125px 20px !important; }
  .single-product-other-info .left h1 {
    font-weight: 400;
    padding-bottom: 20px;
    color: black;
    font-size: 40px !important; }
  .single-product-other-info .left .product-description p, .single-product-other-info .left .product-description ul, .single-product-other-info .left .product-description ol, .single-product-other-info .left .product-description li {
    display: none !important; }
  .single-product-other-info .left .product-description p:first-child {
    display: block !important; }
  .single-product-other-info .left .product-description p {
    max-width: 600px;
    margin: 0 auto !important;
    color: black;
    line-height: 1.75;
    font-weight: 400;
    letter-spacing: 0.5px; }
  .single-product-other-info .left .info-list {
    max-width: 600px;
    margin: 0 auto !important;
    padding-top: 50px; }
    .single-product-other-info .left .info-list .single-info-block img {
      max-width: 45px; }
    .single-product-other-info .left .info-list .single-info-block h6 {
      font-weight: 600;
      padding-top: 15px;
      color: #0c3251;
      text-transform: uppercase;
      letter-spacing: 0.5px; }
.single-product-other-info .right img, .single-product-other-info .right video {
  width: 100% !important; }

@media (max-width: 767px) {
  .single-product-other-info .left {
    padding: 75px 20px !important; } }
@media (max-width: 576px) {
  .single-product-other-info .single-info-block:nth-child(2) {
    padding: 50px 0 !important; } }
.single-product-recommendations-block .contactUsLinkBlock {
  text-align: center;
  padding: 100px 20px; }
  .single-product-recommendations-block .contactUsLinkBlock h1 {
    font-weight: 400;
    padding-top: 20px;
    color: #0c3251; }
  .single-product-recommendations-block .contactUsLinkBlock p {
    max-width: 600px;
    padding: 20px 0 0;
    margin: 0 auto !important;
    color: black;
    line-height: 1.75;
    font-weight: 300;
    letter-spacing: 0.5px; }
    .single-product-recommendations-block .contactUsLinkBlock p a {
      color: #0c3251; }
      .single-product-recommendations-block .contactUsLinkBlock p a:hover {
        color: black;
        border-bottom: 1px solid black; }

.product-template__container {
  padding-top: 10px; }

.single-product-main-info {
  padding-bottom: 75px; }
  .single-product-main-info .right {
    padding: 25px 50px !important; }
    .single-product-main-info .right .product-right-main {
      position: sticky;
      top: 25px; }
    .single-product-main-info .right .product-title {
      position: relative; }
      .single-product-main-info .right .product-title .product__price .price__sale,
      .single-product-main-info .right .product-title .product__price .price__compare,
      .single-product-main-info .right .product-title .product__price .price__regular,
      .single-product-main-info .right .product-title .product__price dt,
      .single-product-main-info .right .product-title .product__price dd {
        margin: 0 auto !important;
        font-family: 'Lexend', sans-serif !important; }
      .single-product-main-info .right .product-title .product__price dd {
        padding: 0 0 0 0; }
      .single-product-main-info .right .product-title .product__price s, .single-product-main-info .right .product-title .product__price span {
        font-weight: 400;
        font-size: 24px !important;
        color: black; }
      .single-product-main-info .right .product-title .product__price .price__badges {
        padding-left: 5px !important; }
        .single-product-main-info .right .product-title .product__price .price__badges span {
          border: 1px solid #0c3251;
          color: white;
          background-color: #0c3251;
          padding: 2px 5px !important;
          font-size: 12px !important;
          border-radius: 0; }
      .single-product-main-info .right .product-title .product__price .price__sale s {
        position: absolute;
        right: 0;
        top: -20px;
        font-size: 17px !important; }
      .single-product-main-info .right .product-title h4 {
        color: #0c3251;
        letter-spacing: 0.5px;
        font-weight: 600; }
      .single-product-main-info .right .product-title .mainsku {
        text-transform: uppercase; }
        .single-product-main-info .right .product-title .mainsku p, .single-product-main-info .right .product-title .mainsku span {
          color: #939598;
          font-weight: 300;
          font-size: 16px !important;
          font-family: 'Manrope', sans-serif !important; }
        .single-product-main-info .right .product-title .mainsku p {
          letter-spacing: 2px; }
        .single-product-main-info .right .product-title .mainsku span {
          letter-spacing: 0.5px; }
      .single-product-main-info .right .product-title .product-tags li {
        text-transform: uppercase;
        background: #0c3251;
        color: white;
        font-family: 'Lexend', sans-serif !important;
        font-size: 12px !important;
        padding: 5px 10px;
        border-radius: 50px;
        text-align: center;
        width: fit-content;
        margin-top: 10px;
        letter-spacing: 0.5px; }
    .single-product-main-info .right .product-form {
      padding-top: 25px !important; }
      .single-product-main-info .right .product-form form {
        padding-top: 0 !important; }
        .single-product-main-info .right .product-form form .product-form__controls-group--submit .product-form__cart-submit {
          margin-bottom: 0 !important;
          border-radius: 50px;
          line-height: initial !important;
          min-height: auto;
          padding: 15px;
          font-weight: 500;
          background: #0c3251;
          font-family: 'Lexend', sans-serif !important; }
          .single-product-main-info .right .product-form form .product-form__controls-group--submit .product-form__cart-submit:hover {
            background: #8baac2;
            transition: .3s; }
        .single-product-main-info .right .product-form form .product-form__controls-group {
          display: block !important; }
          .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item {
            margin-bottom: 0 !important; }
            .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item label[for="SingleOptionSelector-0"],
            .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item label[for="SingleOptionSelector-1"] {
              font-family: 'Lexend', sans-serif !important;
              color: black !important;
              font-weight: 500 !important;
              letter-spacing: 0.5px !important;
              margin-bottom: 0 !important;
              padding-bottom: 10px !important;
              font-size: 18px !important; }
              .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item label[for="SingleOptionSelector-0"] span,
              .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item label[for="SingleOptionSelector-1"] span {
                font-size: 14px !important;
                color: #939598; }
            .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes {
              display: flex;
              flex-wrap: wrap;
              padding-bottom: 20px; }
              .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes .sizes-single.circle {
                padding-right: 5px !important;
                padding-bottom: 5px !important;
                position: relative; }
                .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes .sizes-single.circle input {
                  cursor: pointer;
                  position: absolute;
                  width: 45px;
                  height: 45px;
                  left: 0;
                  top: 0;
                  z-index: 1;
                  opacity: 0; }
                .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes .sizes-single.circle label {
                  border: 1px solid #dddee0;
                  width: 45px;
                  height: 45px;
                  border-radius: 50%;
                  position: relative;
                  overflow: hidden; }
                  .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes .sizes-single.circle label span {
                    position: absolute;
                    width: 100%;
                    text-align: center;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 12px !important;
                    letter-spacing: 0.5px;
                    font-weight: 500; }
                  .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes .sizes-single.circle label.active {
                    border-color: black !important;
                    transition: .3s !important; }
              .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes .sizes-single.full {
                padding-right: 5px !important;
                position: relative; }
                .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes .sizes-single.full input {
                  border-radius: 25px;
                  cursor: pointer;
                  position: absolute;
                  width: 100%;
                  min-height: 100%;
                  z-index: 1;
                  opacity: 0; }
                .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes .sizes-single.full label {
                  border-radius: 25px;
                  padding: 5px 15px !important;
                  border: 1px solid #dddee0; }
                  .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes .sizes-single.full label span {
                    width: 100%;
                    text-align: center;
                    font-size: 12px !important;
                    letter-spacing: 0.5px;
                    font-weight: 500; }
                  .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes .sizes-single.full label.active {
                    border-color: black !important;
                    transition: .3s !important; }
            .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .colors {
              display: flex;
              flex-wrap: wrap;
              padding-bottom: 20px; }
              .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .colors .colors-single {
                padding-right: 5px !important;
                padding-bottom: 5px !important;
                position: relative; }
                .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .colors .colors-single img {
                  border-radius: 50%;
                  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25); }
              .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .colors input {
                cursor: pointer;
                position: absolute;
                width: 45px;
                height: 45px;
                left: 0;
                top: 0;
                z-index: 1;
                opacity: 0; }
              .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .colors label {
                border: 1px solid #dddee0;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                position: relative;
                overflow: hidden; }
                .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .colors label span {
                  border: 3px solid white;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  border-radius: 50%; }
                .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .colors label.active {
                  border-color: black !important;
                  transition: .3s !important; }
    .single-product-main-info .right .product-description {
      padding: 50px 0;
      position: relative; }
      .single-product-main-info .right .product-description h6 {
        padding-bottom: 15px !important;
        color: #0c3251;
        font-weight: 600;
        text-transform: uppercase; }
      .single-product-main-info .right .product-description .size-chart {
        color: #0c3251;
        font-family: 'Lexend', sans-serif !important;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 400;
        border: none;
        padding: 0;
        background: transparent;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 50px; }
      .single-product-main-info .right .product-description .product-description-main {
        font-family: 'Lexend', sans-serif !important;
        color: black;
        letter-spacing: 0.5px;
        font-size: 17px !important; }
        .single-product-main-info .right .product-description .product-description-main p, .single-product-main-info .right .product-description .product-description-main ul, .single-product-main-info .right .product-description .product-description-main ol, .single-product-main-info .right .product-description .product-description-main li {
          font-family: 'Lexend', sans-serif !important; }
        .single-product-main-info .right .product-description .product-description-main ul, .single-product-main-info .right .product-description .product-description-main ol {
          padding-top: 15px !important;
          padding-left: 20px !important; }
          .single-product-main-info .right .product-description .product-description-main ul li, .single-product-main-info .right .product-description .product-description-main ol li {
            list-style: disc;
            letter-spacing: 0.5px; }
        .single-product-main-info .right .product-description .product-description-main p {
          padding-top: 15px !important;
          color: black;
          letter-spacing: 0.5px; }
        .single-product-main-info .right .product-description .product-description-main.yes p:first-child {
          display: none; }
  .single-product-main-info .left .product-images {
    position: sticky;
    top: 0; }
    .single-product-main-info .left .product-images .product-images-single {
      display: none; }
      .single-product-main-info .left .product-images .product-images-single.active {
        display: block; }
      .single-product-main-info .left .product-images .product-images-single img {
        padding: 0 10px 10px 0 !important;
        width: 100%;
        cursor: zoom-in; }
      .single-product-main-info .left .product-images .product-images-single video {
        padding: 0 10px 10px 0 !important;
        height: 883px !important; }

@media (max-width: 1280px) {
  .single-product-main-info .left .product-images video {
    padding: 0 10px 10px 0 !important;
    height: 593px !important; } }
@media (max-width: 1250px) {
  .single-product-main-info .right {
    padding: 20px 15px !important; }
  .single-product-main-info .left .product-images video {
    padding: 0 10px 10px 0 !important;
    height: 593px !important; } }
@media (max-width: 1150px) {
  .single-product-main-info {
    padding-bottom: 0; }
    .single-product-main-info .right .product-description h6 {
      font-size: 18px !important; }
    .single-product-main-info .right .product-title .product__price {
      position: relative; }
      .single-product-main-info .right .product-title .product__price .price__sale s {
        position: relative;
        top: 5px;
        padding: 0 5px 0 10px !important;
        color: #939598; }
    .single-product-main-info .left .product-images video {
      padding: 0 10px 10px 0 !important;
      height: 333px; } }
@media (max-width: 767px) {
  .single-product-main-info .left .product-images {
    padding: 0 8px 5px !important; }
    .single-product-main-info .left .product-images .product-images-single img {
      padding: 0 !important; }
    .single-product-main-info .left .product-images .product-images-single:nth-of-type(odd) {
      padding: 5px !important; }
      .single-product-main-info .left .product-images .product-images-single:nth-of-type(odd) video {
        padding: 0 10px 10px 0 !important;
        height: 350px !important; }
    .single-product-main-info .left .product-images .product-images-single:nth-of-type(even) {
      padding: 5px !important; }
      .single-product-main-info .left .product-images .product-images-single:nth-of-type(even) video {
        padding: 0 10px 10px 0 !important;
        height: 350px !important; }
  .single-product-main-info .right .product-title {
    text-align: center; }
    .single-product-main-info .right .product-title .mainsku {
      text-align: center;
      position: relative; }
    .single-product-main-info .right .product-title .product__price {
      width: fit-content;
      margin: 0 auto; }
  .single-product-main-info .right .product-form form .product-form__controls-group {
    text-align: center; }
    .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .colors,
    .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes {
      width: fit-content;
      margin: 0 auto; }
      .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .colors input,
      .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes input {
        left: 5px;
        top: 5px; }
    .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .colors {
      justify-content: center; }
      .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .colors .colors-single {
        padding: 5px !important; }
    .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes {
      justify-content: center; }
      .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes .sizes-single {
        padding: 5px !important; }
      .single-product-main-info .right .product-form form .product-form__controls-group .product-form__item .sizes label span {
        left: 0; }
  .single-product-main-info .right .product-description {
    padding: 50px 0 0 0; }
    .single-product-main-info .right .product-description .size-chart {
      position: relative;
      top: auto;
      text-align: center;
      width: 100%; }
    .single-product-main-info .right .product-description h6 {
      text-align: center;
      font-size: 22px !important; }
    .single-product-main-info .right .product-description .product-description-main p {
      text-align: center; } }
@media (max-width: 576px) {
  .product-template__container {
    padding-top: 0; }

  .single-product-main-info .left .product-images {
    padding: 0 !important; }
    .single-product-main-info .left .product-images .product-images-single:nth-of-type(odd), .single-product-main-info .left .product-images .product-images-single:nth-of-type(even) {
      padding: 0 !important; } }
/* CART PAGE */
.cartpage .empty-page-content .button {
  padding: 15px 0; }
  .cartpage .empty-page-content .button a {
    color: white;
    font-size: 16px !important;
    font-weight: 500;
    letter-spacing: 1px;
    background-color: #0c3251;
    background-image: unset;
    padding: 14px 35px;
    text-transform: uppercase;
    border-radius: 50px; }
    .cartpage .empty-page-content .button a:hover {
      transition: .3s;
      background-color: #8baac2;
      text-decoration: none; }

.cartpage a:hover {
  text-decoration: none; }
.cartpage .cart__row--heading {
  display: none; }
.cartpage .cart__row {
  border-color: #dddee0 !important; }
.cartpage .cart__image-wrapper {
  padding: 15px !important; }
.cartpage .cart__image {
  max-height: unset !important; }
.cartpage .cart__row td {
  vertical-align: middle;
  padding: 10px !important; }
.cartpage tbody {
  border-top: 1px solid #dddee0; }
.cartpage .cart-note__input {
  min-height: 100px; }
.cartpage .cart__price {
  display: none; }
.cartpage .empty-page-content {
  padding: 80px 20px 100px; }
  .cartpage .empty-page-content .cart--empty-message {
    letter-spacing: 0.5px;
    font-size: 25px !important;
    margin-bottom: 25px !important; }
.cartpage .main {
  padding: 80px 20px; }
  .cartpage .main table th {
    padding: 0 0 10px 0;
    color: black;
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize; }
  .cartpage .main .cart__row .text-link--accent {
    color: #0c3251;
    border-bottom: none; }
  .cartpage .main .cart__row .cart__product-title {
    color: #0c3251 !important;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-size: 25px !important;
    font-family: 'Lexend', sans-serif !important; }
    .cartpage .main .cart__row .cart__product-title span {
      padding-left: 10px !important;
      font-size: 17px !important;
      color: #939598 !important; }
  .cartpage .main .cart__row .titleblock {
    align-self: center; }
  .cartpage .main .cart__row .cart__price dd {
    margin: 0 !important;
    color: black;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.5px; }
  .cartpage .main .cart__row .cart__final-price {
    color: black;
    font-family: 'Lexend', sans-serif !important;
    font-weight: 400;
    letter-spacing: 0.5px; }
    .cartpage .main .cart__row .cart__final-price span {
      font-size: 20px !important; }
  .cartpage .main .cart__row .cart__qty-input {
    border-color: #0c3251;
    border-radius: 0;
    width: 100px;
    text-align: center;
    padding: 15px 0 15px 17px;
    font-size: 15px;
    font-weight: 400;
    color: #939598; }
  .cartpage .main .cart__row .cart__product-title:hover {
    border-bottom: none; }
  .cartpage .main .cart__row a:focus {
    border-bottom: none; }
  .cartpage .main .cart-note {
    padding-left: 0;
    float: none;
    padding-bottom: 25px; }
    .cartpage .main .cart-note p {
      color: black;
      padding-bottom: 20px; }
    .cartpage .main .cart-note .cart-note__label {
      font-size: 20px;
      font-weight: 600;
      color: #0c3251;
      margin-bottom: 0;
      padding: 0 0 5px;
      font-family: 'Lexend', sans-serif !important; }
    .cartpage .main .cart-note textarea {
      border-color: #dddee0;
      border-radius: 0;
      font-size: 16px;
      font-weight: 400; }
  .cartpage .main .cart-subtotal span {
    color: black;
    font-size: 22px !important; }
  .cartpage .main .cart-subtotal .cart-subtotal__title {
    font-weight: 600; }
  .cartpage .main .cart-subtotal .cart-subtotal__price {
    font-weight: 400;
    padding: 0;
    color: black;
    font-family: 'Lexend', sans-serif !important; }
  .cartpage .main .cart__shipping {
    font-size: 18px;
    font-weight: 400;
    color: black;
    padding-top: 20px; }
.cartpage .cart__removed-product {
  color: black; }
.cartpage .cartfooter .continueshopping {
  align-self: top;
  text-align: left;
  letter-spacing: 1px; }
  .cartpage .cartfooter .continueshopping a {
    border-bottom: 2px solid #8baac2;
    font-weight: 600;
    padding: 5px;
    color: #8baac2; }
    .cartpage .cartfooter .continueshopping a:hover {
      border-color: #0c3251;
      color: #0c3251;
      transition: .3s; }
.cartpage .cartfooter .cart-subtotal {
  position: relative; }
  .cartpage .cartfooter .cart-subtotal .mtextb {
    display: flex;
    padding-bottom: 20px; }
  .cartpage .cartfooter .cart-subtotal .text {
    padding: 0 20px;
    width: 100%;
    max-width: 220px; }
    .cartpage .cartfooter .cart-subtotal .text .cart-subtotal__title {
      text-align: left;
      font-size: 22px;
      color: #0c3251;
      font-family: 'Lexend', sans-serif !important; }
    .cartpage .cartfooter .cart-subtotal .text .cart__shipping.rte {
      padding: 0 !important;
      color: #939598;
      font-size: 12px !important;
      text-align: left;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 1.25; }
  .cartpage .cartfooter .cart-subtotal .text2 {
    text-align: right;
    width: fit-content; }
.cartpage .cart__submit-controls input {
  font-size: 15px !important;
  color: white;
  background-color: #0c3251;
  padding: 12px 35px;
  border-radius: 0;
  border-radius: 50px; }
  .cartpage .cart__submit-controls input:hover {
    background-color: #8baac2;
    transition: .3s; }
.cartpage #cart__remove {
  display: none; }
.cartpage .carttotalblock .carttotalblockmain {
  max-width: 335px;
  margin: 0 0 0 auto; }

@media (max-width: 767px) {
  .cartpage .main {
    padding: 50px 20px 50px 20px; }

  .cartpage .main .cart-note {
    margin: 0 auto; }
    .cartpage .main .cart-note .cart-note__input {
      margin-bottom: 0; }

  .cartpage .cartfooter .continueshopping {
    order: 2;
    text-align: center; }
  .cartpage .cartfooter .carttotalblock {
    order: 1; }

  .cartpage .cartfooter .continueshopping .view-all-shop-page-link.button {
    padding-top: 50px; }

  .cartpage .cartfooter .cart-subtotal .text {
    max-width: 100%; }

  .cartpage .cartfooter .cart-subtotal .text .cart-subtotal__title,
  .cartpage .cartfooter .cart-subtotal .text .cart__shipping.rte {
    text-align: center; }

  .cartpage .cartfooter .cart-subtotal .text2 {
    width: 100%;
    padding-top: 20px; }

  .cartpage .cartfooter .cart-subtotal .mtextb {
    margin: 0 auto;
    display: block; }

  .cart__qty-label {
    display: none !important; }

  #cart__remove {
    display: block !important; }
    #cart__remove a {
      font-size: 14px;
      border-bottom: 1px solid #0c3251; }

  .cart__buttons-container {
    max-width: fit-content; } }
@media (max-width: 749px) {
  .cartpage .cart__price {
    display: block !important;
    margin: 0 0 0 auto; } }
.template-cart .product-details {
  margin-top: 0 !important;
  padding-top: 10px !important; }
  .template-cart .product-details .product-details__item {
    font-family: 'Lexend', sans-serif !important;
    margin-bottom: 0 !important;
    color: #0c3251;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: inline-block; }
    .template-cart .product-details .product-details__item i {
      font-size: 5px;
      vertical-align: middle;
      padding: 0 5px; }
.template-cart .cart__remove {
  margin-top: 10px !important;
  margin-bottom: 0 !important; }
  .template-cart .cart__remove a {
    color: #8baac2 !important;
    border-color: #8baac2 !important;
    font-size: 17px !important; }

@media (max-width: 767px) {
  .template-cart .product-details .product-details__item {
    font-size: 10px !important; }
  .template-cart .cart__remove a {
    font-size: 12px !important; }

  .cart__row .cart__price span {
    font-family: 'Lexend', sans-serif !important; }
  .cart__row td {
    padding: 10px 0 !important; }

  .cartpage .main .cart__row .cart__product-title {
    font-size: 17px !important; }
  .cartpage .cart__image-wrapper {
    padding: 0 10px 0 0 !important; }
  .cartpage .cartfooter .cart-subtotal .text2 {
    text-align: center; }
  .cartpage .carttotalblock .carttotalblockmain {
    margin: 0 auto; } }
/* Add to cart popup */
body.cartpopupopen {
  position: relative;
  overflow: hidden; }
  body.cartpopupopen::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.25); }

.cart-popup-side {
  position: fixed;
  top: 0;
  right: 0;
  height: auto;
  width: 100%;
  max-width: 500px;
  background-color: white;
  z-index: 100;
  transform: translateX(100%);
  transition: all 0.45s cubic-bezier(0.29, 0.63, 0.44, 1);
  padding: 75px 30px 25px; }
  .cart-popup-side .x-button {
    position: absolute;
    right: 25px;
    top: 25px;
    z-index: 9;
    cursor: pointer; }
    .cart-popup-side .x-button i {
      font-size: 35px;
      color: #0c3251; }
  .cart-popup-side .popup-drop {
    margin: 20px 0;
    height: 450px;
    overflow: scroll; }
  .cart-popup-side .popup-flex {
    display: block; }
  .cart-popup-side .sku {
    font-size: 12px !important;
    padding: 5px 0 0 0;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 0.5px; }
  .cart-popup-side .popup-bottom .link a {
    background-color: #0c3251;
    color: white;
    width: 100%;
    display: block;
    text-align: center; }
    .cart-popup-side .popup-bottom .link a:hover {
      background-color: #8baac2; }
  .cart-popup-side .popup-bottom .subtotal-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; }
    .cart-popup-side .popup-bottom .subtotal-container h5 {
      color: #0c3251;
      font-weight: 400;
      font-family: 'Lexend', sans-serif !important; }
  .cart-popup-side .side-cart-title {
    color: #0c3251;
    font-weight: 400; }
    .cart-popup-side .side-cart-title span {
      font-size: 20px !important; }
  .cart-popup-side .ind-product-cart {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #dddee0;
    position: relative; }
    .cart-popup-side .ind-product-cart .cart-product-text-container .variant-str {
      padding-top: 10px;
      font-family: 'Lexend', sans-serif !important;
      color: #0c3251;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 13px !important; }
      .cart-popup-side .ind-product-cart .cart-product-text-container .variant-str i {
        font-size: 5px;
        vertical-align: middle;
        padding: 0 5px; }
    .cart-popup-side .ind-product-cart .cart-product-text-container .cart-remove {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 20px; }
      .cart-popup-side .ind-product-cart .cart-product-text-container .cart-remove span {
        color: black; }
    .cart-popup-side .ind-product-cart .cart-product-text-container h4 {
      font-size: 20px !important; }
    .cart-popup-side .ind-product-cart .cart-product-text-container h4, .cart-popup-side .ind-product-cart .cart-product-text-container .side-cart-price {
      color: #0c3251;
      font-weight: 400 !important;
      font-family: 'Lexend', sans-serif !important; }
    .cart-popup-side .ind-product-cart .cart-product-text-container .quantity-controls-container {
      display: flex;
      font-size: 16px;
      margin: 10px 0 0 0;
      border-radius: 50px;
      background: #f3f5f6;
      width: fit-content; }
      .cart-popup-side .ind-product-cart .cart-product-text-container .quantity-controls-container > div {
        height: 35px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .cart-popup-side .ind-product-cart .cart-product-text-container .quantity-controls-container > div.minus-button, .cart-popup-side .ind-product-cart .cart-product-text-container .quantity-controls-container > div.plus-button {
          cursor: pointer;
          width: 40px; }
      .cart-popup-side .ind-product-cart .cart-product-text-container .quantity-controls-container .plus-button, .cart-popup-side .ind-product-cart .cart-product-text-container .quantity-controls-container .minus-button {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 100; }
      .cart-popup-side .ind-product-cart .cart-product-text-container .quantity-controls-container .plus-button:after {
        content: '\f067'; }
      .cart-popup-side .ind-product-cart .cart-product-text-container .quantity-controls-container .minus-button:after {
        content: '\f068'; }
    .cart-popup-side .ind-product-cart:last-of-type {
      border: none; }
    .cart-popup-side .ind-product-cart img {
      width: 125px; }
    .cart-popup-side .ind-product-cart .cart-product-text-container {
      padding-left: 20px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
  .cart-popup-side.open {
    transform: translateX(0%); }

@media (max-width: 767px) {
  .cart-popup-side {
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 50px 20px 25px 20px; }
    .cart-popup-side .popup-drop {
      height: auto; }
    .cart-popup-side .side-cart-title {
      position: fixed;
      z-index: 1;
      background: white;
      width: 100%;
      padding: 24px 20px;
      left: 0;
      top: 0; }
    .cart-popup-side .popup-top {
      position: relative;
      height: 80%;
      overflow: hidden;
      overflow: scroll; }
    .cart-popup-side .popup-bottom {
      position: absolute;
      width: 100%;
      height: 20%;
      bottom: 0;
      left: 0;
      padding: 20px; } }
/* Product Card Grid */
div.single-product-block {
  padding: 10px !important;
  overflow: hidden; }
  div.single-product-block .product-tags {
    padding-bottom: 5px; }
    div.single-product-block .product-tags li {
      color: #0c3251;
      font-family: 'Lexend', sans-serif !important;
      font-size: 14px !important;
      letter-spacing: 0.5px;
      text-transform: uppercase; }
  div.single-product-block .single-product-grid .images {
    position: relative;
    z-index: 1;
    cursor: pointer; }
    div.single-product-block .single-product-grid .images .closeVar {
      position: absolute;
      top: 20px;
      right: 20px;
      opacity: 0;
      z-index: 0; }
      div.single-product-block .single-product-grid .images .closeVar i {
        color: white;
        font-size: 20px; }
      div.single-product-block .single-product-grid .images .closeVar.showlink {
        opacity: 1;
        z-index: 9; }
    div.single-product-block .single-product-grid .images::after {
      transition: 400ms all;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      background: rgba(0, 0, 0, 0.25); }
    div.single-product-block .single-product-grid .images.openVars::after {
      opacity: 1 !important; }
    div.single-product-block .single-product-grid .images .product-images-single {
      position: relative;
      display: none;
      width: 100%;
      height: 450px; }
      div.single-product-block .single-product-grid .images .product-images-single img {
        width: 100%;
        height: 450px;
        object-fit: cover; }
      div.single-product-block .single-product-grid .images .product-images-single.active {
        display: block; }
      div.single-product-block .single-product-grid .images .product-images-single .product-img-overlay-single-prod {
        z-index: 1;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.05); }
    div.single-product-block .single-product-grid .images form .product-form__item {
      display: block !important; }
      div.single-product-block .single-product-grid .images form .product-form__item:last-child {
        display: none !important; }
      div.single-product-block .single-product-grid .images form .product-form__item.product-form__item--submit {
        display: block !important; }
    div.single-product-block .single-product-grid .images .quickaddLink {
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: 10px;
      padding: 0 15px !important;
      z-index: 1;
      opacity: 0;
      transition: 400ms all; }
      div.single-product-block .single-product-grid .images .quickaddLink p {
        cursor: pointer;
        display: block;
        width: 100%;
        text-transform: uppercase;
        padding: 15px 30px;
        font-weight: 600;
        font-size: 15px !important;
        letter-spacing: 1px;
        border-radius: 50px;
        background-color: white !important;
        color: #0c3251 !important; }
        div.single-product-block .single-product-grid .images .quickaddLink p:hover {
          text-decoration: none;
          transition: .3s; }
      div.single-product-block .single-product-grid .images .quickaddLink.closeQuickLinkButton {
        opacity: 0 !important; }
  div.single-product-block .single-product-grid:hover .images::after {
    opacity: 1; }
  div.single-product-block .single-product-grid:hover .images .quickaddLink {
    opacity: 1; }
  div.single-product-block .product-single__meta {
    display: flex; }
    div.single-product-block .product-single__meta form {
      padding: 0 !important; }
    div.single-product-block .product-single__meta .title_price {
      width: 65%; }
      div.single-product-block .product-single__meta .title_price h5 {
        color: black;
        font-weight: 400;
        font-size: 18px !important; }
    div.single-product-block .product-single__meta .formmain {
      width: 35%;
      text-align: right; }
  div.single-product-block .product-form__controls-group {
    display: block; }
    div.single-product-block .product-form__controls-group.product-form__controls-group--submit {
      position: absolute;
      width: 100%;
      left: 0;
      top: 382px;
      margin-top: 15px;
      padding: 0 25px 10px !important;
      opacity: 0;
      z-index: 0; }
      div.single-product-block .product-form__controls-group.product-form__controls-group--submit button {
        margin-bottom: 0 !important;
        border-radius: 50px;
        line-height: initial !important;
        min-height: auto;
        padding: 15px;
        font-weight: 500;
        background: white;
        color: #0c3251;
        font-family: 'Lexend', sans-serif !important; }
        div.single-product-block .product-form__controls-group.product-form__controls-group--submit button:hover {
          color: white;
          background-color: #0c3251;
          transition: .3s; }
      div.single-product-block .product-form__controls-group.product-form__controls-group--submit.openvar {
        opacity: 1;
        z-index: 1; }
    div.single-product-block .product-form__controls-group .product-form__item {
      margin: 0 !important; }
      div.single-product-block .product-form__controls-group .product-form__item.productformSize {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 50%;
        transform: translateY(50%);
        padding: 0 10px;
        opacity: 0;
        z-index: 0; }
        div.single-product-block .product-form__controls-group .product-form__item.productformSize.openvar {
          opacity: 1;
          z-index: 1; }
      div.single-product-block .product-form__controls-group .product-form__item.productformColour {
        position: relative;
        right: 10px;
        width: 105px;
        padding-top: 5px; }
      div.single-product-block .product-form__controls-group .product-form__item label {
        margin: 0 !important;
        font-family: 'Lexend', sans-serif !important;
        color: #939598;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-size: 10px;
        padding-bottom: 5px; }
      div.single-product-block .product-form__controls-group .product-form__item .sizes {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px !important;
        justify-content: center; }
        div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.circle {
          padding-left: 2px !important;
          padding-right: 2px !important;
          padding-bottom: 10px !important;
          position: relative; }
          div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.circle:hover label {
            background-color: white;
            transition: .3s; }
            div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.circle:hover label span {
              color: black; }
          div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.circle input {
            cursor: pointer;
            position: absolute;
            width: 50px;
            height: 30px;
            min-height: 30px;
            left: 2px;
            top: 0;
            z-index: 1;
            opacity: 0; }
          div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.circle label {
            width: 50px;
            height: 30px;
            min-height: 30px;
            position: relative;
            overflow: hidden;
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: 20px;
            padding-bottom: 0; }
            div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.circle label span {
              position: absolute;
              width: 100%;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              text-align: center;
              color: black;
              font-size: 14px !important; }
            div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.circle label.active {
              background-color: #0c3251;
              transition: .3s !important; }
              div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.circle label.active span {
                color: white; }
        div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.full {
          padding-left: 2px !important;
          padding-right: 2px !important;
          padding-bottom: 10px !important;
          position: relative; }
          div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.full:hover label {
            background-color: white;
            transition: .3s; }
            div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.full:hover label span {
              color: black; }
          div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.full input {
            cursor: pointer;
            position: absolute;
            width: 100%;
            min-height: 100%;
            left: 2px;
            top: 0;
            z-index: 1;
            opacity: 0; }
          div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.full label {
            position: relative;
            overflow: hidden;
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: 20px;
            padding: 5px 15px !important; }
            div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.full label span {
              text-align: center;
              color: black;
              font-size: 14px !important; }
            div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.full label.active {
              background-color: #0c3251;
              transition: .3s !important; }
              div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.full label.active span {
                color: white; }
      div.single-product-block .product-form__controls-group .product-form__item .colors {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end; }
        div.single-product-block .product-form__controls-group .product-form__item .colors .colors-single {
          padding-left: 3px !important;
          padding-bottom: 5px !important;
          position: relative; }
          div.single-product-block .product-form__controls-group .product-form__item .colors .colors-single img {
            border-radius: 50%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.25); }
        div.single-product-block .product-form__controls-group .product-form__item .colors input {
          cursor: pointer;
          position: absolute;
          width: 15px;
          height: 15px;
          min-height: 15px;
          left: 3px;
          top: 0;
          z-index: 1;
          opacity: 0; }
        div.single-product-block .product-form__controls-group .product-form__item .colors label {
          border: 1px solid #dddee0;
          width: 15px;
          height: 15px;
          min-height: 15px;
          border-radius: 50%;
          position: relative;
          overflow: hidden; }
          div.single-product-block .product-form__controls-group .product-form__item .colors label span {
            border: 1px solid white;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border-radius: 50%; }
          div.single-product-block .product-form__controls-group .product-form__item .colors label.active {
            border-color: rgba(0, 0, 0, 0.5) !important;
            transition: .3s !important; }
  div.single-product-block .product__price {
    width: fit-content;
    display: inline-flex; }
    div.single-product-block .product__price a {
      align-self: center;
      padding-left: 5px;
      font-size: 20px !important; }
    div.single-product-block .product__price .price__sale,
    div.single-product-block .product__price .price__compare,
    div.single-product-block .product__price .price__regular,
    div.single-product-block .product__price dt,
    div.single-product-block .product__price dd {
      margin: 0 auto !important;
      font-family: 'Lexend', sans-serif !important; }
    div.single-product-block .product__price dd {
      padding: 0 0 0 0; }
    div.single-product-block .product__price s, div.single-product-block .product__price span {
      font-weight: 400;
      font-size: 18px !important;
      color: black; }
    div.single-product-block .product__price .price__badges .price__badge {
      position: absolute;
      z-index: 1;
      left: 20px;
      top: 20px;
      width: 55px;
      height: 55px;
      background: #0c3251;
      border-radius: 50%;
      padding-left: 0 !important;
      border: none;
      padding: 0 !important; }
      div.single-product-block .product__price .price__badges .price__badge span {
        text-transform: Capitalize;
        color: white;
        font-size: 12px !important;
        border-radius: 0;
        position: absolute;
        width: 100%;
        text-align: center;
        background: transparent;
        border: none;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 10px; }
      div.single-product-block .product__price .price__badges .price__badge.price__badge--sold-out span {
        line-height: 1; }
    div.single-product-block .product__price .price__sale s {
      padding: 0 2px 0 5px !important;
      color: #939598;
      font-size: 18px !important; }
  div.single-product-block .product-info {
    padding: 15px 10px !important; }

body.template-collection.modal-open::after {
  display: none; }
body.template-collection.modal-open .modal {
  background: white !important; }

#shopify-section-product-recommendations {
  padding: 50px 10px !important; }
  #shopify-section-product-recommendations h1 {
    color: #0c3251 !important;
    text-transform: uppercase !important;
    font-weight: 500;
    font-size: 35px !important;
    padding: 0 10px 10px; }
  #shopify-section-product-recommendations div.single-product-block .product-single__meta .formmain {
    display: none !important; }
  #shopify-section-product-recommendations div.single-product-block .single-product-grid .images .quickaddLink {
    display: none !important; }

@media (max-width: 767px) {
  #Collection > div {
    width: 50% !important;
    flex: 0 0 50%;
    max-width: 50%; }

  #shopify-section-product-recommendations h1 {
    padding: 0 10px 10px; }
  #shopify-section-product-recommendations .recommendations-slider > div {
    width: 50% !important;
    flex: 0 0 50%;
    max-width: 50%; }

  div.single-product-block {
    padding: 5px !important; }
    div.single-product-block .single-product-grid .images .product-images-single {
      height: 300px; }
      div.single-product-block .single-product-grid .images .product-images-single img {
        height: 300px; }
    div.single-product-block .single-product-grid .images .closeVar {
      top: 5px;
      right: 5px; }
    div.single-product-block .single-product-grid .images .quickaddLink {
      bottom: 10px;
      padding: 0 10px !important; }
      div.single-product-block .single-product-grid .images .quickaddLink p {
        padding: 5px 0 !important;
        font-size: 12px !important; }
    div.single-product-block .product-form__controls-group.product-form__controls-group--submit {
      padding: 1px 15px 15px !important;
      top: 252px; }
      div.single-product-block .product-form__controls-group.product-form__controls-group--submit button {
        padding: 5px 0 !important;
        letter-spacing: 0.5px; }
        div.single-product-block .product-form__controls-group.product-form__controls-group--submit button span {
          font-size: 12px !important; }
    div.single-product-block .product-form__controls-group .product-form__item .sizes .sizes-single.full label span {
      font-size: 10px !important; }
    div.single-product-block .product-form__controls-group .product-form__item .colors {
      justify-content: center; }
    div.single-product-block .product-form__controls-group .product-form__item.productformColour {
      position: relative;
      right: 0;
      width: 100%; }
      div.single-product-block .product-form__controls-group .product-form__item.productformColour > label {
        padding-top: 10px;
        text-align: center; }
    div.single-product-block .product__price s, div.single-product-block .product__price span {
      font-size: 15px !important; }
    div.single-product-block .product__price .price__sale s {
      font-size: 15px !important; }
    div.single-product-block .product-tags li {
      font-size: 10px !important; }
    div.single-product-block .product-info {
      padding: 5px !important;
      text-align: center; }
    div.single-product-block .product-single__meta {
      display: block !important; }
      div.single-product-block .product-single__meta .title_price {
        width: 100% !important; }
        div.single-product-block .product-single__meta .title_price h5 {
          font-size: 17px !important; }
      div.single-product-block .product-single__meta .formmain {
        width: 100% !important; }
    div.single-product-block .product__price .price__badges .price__badge {
      width: 35px;
      height: 35px;
      left: 10px;
      top: 10px; }
      div.single-product-block .product__price .price__badges .price__badge span {
        padding: 5px;
        font-size: 8px !important;
        letter-spacing: 0.5px; } }
.custom-collection-template .left {
  padding: 25px 20px !important; }
  .custom-collection-template .left .tag-filters {
    position: sticky;
    top: 0;
    padding-top: 30px; }
    .custom-collection-template .left .tag-filters .title {
      color: #0c3251;
      font-weight: 600;
      font-size: 22px !important;
      letter-spacing: 0.5px;
      padding-bottom: 35px; }
    .custom-collection-template .left .tag-filters .stitle {
      position: relative;
      color: #0c3251;
      padding: 15px 0;
      text-transform: uppercase;
      font-size: 16px !important;
      letter-spacing: 1px;
      border-top: 1px solid #dddee0;
      border-bottom: 1px solid #dddee0;
      cursor: pointer; }
      .custom-collection-template .left .tag-filters .stitle i {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%); }
    .custom-collection-template .left .tag-filters .sizes_list {
      display: none; }
    .custom-collection-template .left .tag-filters .color_list {
      display: none; }
    .custom-collection-template .left .tag-filters .size_list {
      padding-top: 15px;
      display: flex;
      flex-wrap: wrap; }
      .custom-collection-template .left .tag-filters .size_list li {
        padding: 8px 10px;
        width: 60px;
        text-align: center;
        margin-right: 10px;
        margin-bottom: 12px;
        border: 1px solid #dddee0;
        border-radius: 5px;
        cursor: pointer; }
        .custom-collection-template .left .tag-filters .size_list li p {
          align-self: center;
          font-family: 'Manrope', sans-serif !important;
          letter-spacing: 1px;
          font-size: 12px !important;
          cursor: pointer; }
        .custom-collection-template .left .tag-filters .size_list li.active {
          background: #dddee0; }
          .custom-collection-template .left .tag-filters .size_list li.active p {
            font-weight: 700; }
    .custom-collection-template .left .tag-filters .color_list {
      padding-top: 15px;
      padding-left: 10px; }
      .custom-collection-template .left .tag-filters .color_list li {
        display: flex;
        padding: 10px 0; }
        .custom-collection-template .left .tag-filters .color_list li div {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
          width: 25px;
          height: 25px;
          border-radius: 50%;
          overflow: hidden; }
          .custom-collection-template .left .tag-filters .color_list li div img {
            border-radius: 50%;
            width: 100%; }
        .custom-collection-template .left .tag-filters .color_list li p {
          align-self: center;
          font-family: 'Manrope', sans-serif !important;
          letter-spacing: 1px;
          font-size: 14px !important;
          padding: 0 10px !important;
          cursor: pointer; }
        .custom-collection-template .left .tag-filters .color_list li.active div {
          border: 1px solid black; }
          .custom-collection-template .left .tag-filters .color_list li.active div img {
            border: 2px solid white; }
.custom-collection-template .right {
  padding: 0 10px !important; }
  .custom-collection-template .right .TitleandFilter {
    display: flex;
    padding: 0 10px 20px !important; }
    .custom-collection-template .right .TitleandFilter .title {
      align-self: center;
      width: 50%; }
    .custom-collection-template .right .TitleandFilter .filter {
      align-self: center;
      text-align: right;
      width: 50%; }
      .custom-collection-template .right .TitleandFilter .filter .filters-toolbar {
        width: fit-content;
        margin: 0 0 0 auto; }
      .custom-collection-template .right .TitleandFilter .filter select {
        -webkit-appearance: inherit;
        text-align-last: center;
        font-family: 'Manrope', sans-serif !important;
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #939598;
        padding-right: 25px; }
      .custom-collection-template .right .TitleandFilter .filter svg {
        fill: #939598; }
    .custom-collection-template .right .TitleandFilter h4 {
      color: #0c3251;
      text-transform: uppercase;
      font-weight: 500; }
    .custom-collection-template .right .TitleandFilter .filters-toolbar-wrapper {
      border: none;
      margin: 0; }
      .custom-collection-template .right .TitleandFilter .filters-toolbar-wrapper .filters-toolbar__item-child {
        margin: 0; }
      .custom-collection-template .right .TitleandFilter .filters-toolbar-wrapper label {
        margin: 0;
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #939598;
        font-family: 'Manrope', sans-serif !important; }
      .custom-collection-template .right .TitleandFilter .filters-toolbar-wrapper select {
        height: auto; }
.custom-collection-template .titleBlock {
  padding: 100px 0;
  overflow: hidden; }
  .custom-collection-template .titleBlock .tagsBlock {
    transform: scale(1.03); }
  .custom-collection-template .titleBlock .owl-prev,
  .custom-collection-template .titleBlock .owl-next {
    position: absolute;
    top: 41%; }
    .custom-collection-template .titleBlock .owl-prev i,
    .custom-collection-template .titleBlock .owl-next i {
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
    .custom-collection-template .titleBlock .owl-prev.disabled,
    .custom-collection-template .titleBlock .owl-next.disabled {
      display: none !important; }
  .custom-collection-template .titleBlock .owl-prev {
    left: 30px;
    font-size: 30px !important;
    color: white !important; }
  .custom-collection-template .titleBlock .owl-next {
    right: 30px;
    font-size: 30px !important;
    color: white !important; }
  .custom-collection-template .titleBlock h1 {
    text-align: center;
    padding-bottom: 35px;
    letter-spacing: 0.5px;
    color: #0c3251;
    font-weight: 400; }
  .custom-collection-template .titleBlock .owl-stage {
    margin: 0 auto; }
  .custom-collection-template .titleBlock .owl-item {
    border-left: 8px solid white;
    border-right: 8px solid white; }
  .custom-collection-template .titleBlock .owl-stage .owl-item.active:first-child {
    border-left: 8px solid transparent; }
  .custom-collection-template .titleBlock .owl-stage .owl-item.active:last-child {
    border-right: 8px solid transparent; }
  .custom-collection-template .titleBlock .tags p {
    text-align: center;
    padding: 10px 20px; }
    .custom-collection-template .titleBlock .tags p a {
      color: black;
      letter-spacing: 0.5px;
      font-family: 'Lexend', sans-serif !important; }
      .custom-collection-template .titleBlock .tags p a:hover {
        text-decoration: none; }
  .custom-collection-template .titleBlock .tags .image {
    width: 100%;
    height: 450px;
    position: relative; }
    .custom-collection-template .titleBlock .tags .image .product-img-overlay-single-prod {
      z-index: 1;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.05); }
    .custom-collection-template .titleBlock .tags .image a {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 2; }
    .custom-collection-template .titleBlock .tags .image img {
      position: absolute;
      object-fit: cover;
      height: 100%; }
  .custom-collection-template .titleBlock .tags.active p a {
    color: #0c3251;
    font-weight: 600; }
  .custom-collection-template .titleBlock .tags.active .image::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #0c3251;
    opacity: 0.15; }
.custom-collection-template .loadLink {
  padding: 25px 0 75px; }
.custom-collection-template .to_top {
  padding: 10px 0 0; }
.custom-collection-template .to_top, .custom-collection-template .to_top a {
  text-align: center;
  font-family: 'Lexend', sans-serif !important;
  color: #0c3251;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-transform: uppercase; }
.custom-collection-template .to_top a {
  cursor: pointer; }
  .custom-collection-template .to_top a:hover {
    color: black; }
.custom-collection-template .load-more_wrap {
  padding: 0 20px 0;
  text-align: center; }
  .custom-collection-template .load-more_wrap button {
    font-family: 'Lexend', sans-serif !important;
    background-color: #0c3251;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
    border-radius: 50px;
    padding: 10px 40px; }
    .custom-collection-template .load-more_wrap button:hover {
      background-color: #8baac2; }

@media (max-width: 767px) {
  .custom-collection-template .right .TitleandFilter {
    display: block;
    text-align: center; }
    .custom-collection-template .right .TitleandFilter .filter {
      width: 100%; }
      .custom-collection-template .right .TitleandFilter .filter .filters-toolbar {
        width: 100%; }
        .custom-collection-template .right .TitleandFilter .filter .filters-toolbar .filters-toolbar__item-wrapper {
          display: block; }
          .custom-collection-template .right .TitleandFilter .filter .filters-toolbar .filters-toolbar__item-wrapper .filters-toolbar__item-child {
            width: fit-content;
            display: flex;
            margin: 0 auto; }
    .custom-collection-template .right .TitleandFilter .title {
      width: 100%;
      padding-bottom: 25px; }
  .custom-collection-template .titleBlock {
    padding: 50px 0; }
    .custom-collection-template .titleBlock .owl-item {
      border-width: 5px !important; }
    .custom-collection-template .titleBlock h1 {
      padding: 0 20px 25px;
      font-size: 35px !important; } }
.site-footer {
  margin-top: 0 !important;
  padding: 0 !important; }

#shopify-section-collection-stethoscopes .custom-collection-template .titleBlock .tags .image img {
  object-fit: contain; }

@media (max-width: 767px) {
  #shopify-section-collection-stethoscopes .custom-collection-template .titleBlock .owl-next,
  #shopify-section-collection-stethoscopes .custom-collection-template .titleBlock .owl-prev {
    top: 36%; }
  #shopify-section-collection-stethoscopes .custom-collection-template .titleBlock .owl-prev {
    left: 15px; }
  #shopify-section-collection-stethoscopes .custom-collection-template .titleBlock .owl-next {
    right: 15px; }
  #shopify-section-collection-stethoscopes .custom-collection-template .titleBlock .tags .image {
    height: 250px; } }
.homehero {
  position: relative; }
  .homehero .owl-prev {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%); }
    .homehero .owl-prev i {
      font-size: 40px;
      color: #0c3251; }
  .homehero .owl-next {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%); }
    .homehero .owl-next i {
      font-size: 40px;
      color: #0c3251; }

@media (max-width: 767px) {
  .custom-collection-template .left .tag-filters {
    padding: 0; }
    .custom-collection-template .left .tag-filters .title {
      cursor: pointer;
      text-align: center;
      background: #dddee0;
      padding: 10px; }
    .custom-collection-template .left .tag-filters .colours,
    .custom-collection-template .left .tag-filters .sizes {
      display: none; }
      .custom-collection-template .left .tag-filters .colours .color_list,
      .custom-collection-template .left .tag-filters .sizes .color_list {
        display: flex;
        flex-wrap: wrap; } }
.searchpagemain .left {
  padding: 0 20px 25px 20px !important; }
  .searchpagemain .left .tag-filters {
    padding-top: 20px; }
.searchpagemain .predictive-search {
  display: none !important; }
.searchpagemain .titleBlock {
  padding: 100px 20px; }
.searchpagemain form {
  max-width: 800px;
  margin: 0 auto; }
  .searchpagemain form .search-form__connected-submit {
    background-color: #0c3251 !important; }
.searchpagemain .search--no-results-found {
  padding: 100px 20px 0;
  text-align: center; }
  .searchpagemain .search--no-results-found p {
    font-size: 20px !important;
    letter-spacing: 0.5px; }

/* .custom-collection-template {
  &.men {
    .left {
      .tag-filters {
        .color_list {
          height: 625px;
          overflow: scroll;
        }
      }
    }
  }
  &.women {
    .left {
      .tag-filters {
        .color_list {
          height: 625px;
          overflow: scroll;
        }
      }
    }
  }
} */
.womenmen {
  display: none;
  position: absolute;
  width: 100%;
  z-index: 99;
  background: white;
  padding: 35px 50px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25); }
  .womenmen .left {
    padding: 50px 0; }
    .womenmen .left p {
      color: #0c3251;
      padding-bottom: 10px;
      letter-spacing: 0.5px; }
    .womenmen .left .one,
    .womenmen .left .two,
    .womenmen .left .three {
      padding: 0 20px !important; }
      .womenmen .left .one ul li,
      .womenmen .left .two ul li,
      .womenmen .left .three ul li {
        padding-top: 2px; }
        .womenmen .left .one ul li a,
        .womenmen .left .two ul li a,
        .womenmen .left .three ul li a {
          font-size: 16px !important;
          letter-spacing: 0.5px; }
          .womenmen .left .one ul li a:hover,
          .womenmen .left .two ul li a:hover,
          .womenmen .left .three ul li a:hover {
            text-decoration: none;
            color: #0c3251; }
  .womenmen .right {
    overflow: hidden;
    background-size: cover !important;
    background-position: center !important; }
    .womenmen .right .content {
      padding: 50px 20px !important; }
      .womenmen .right .content .small_title {
        text-transform: uppercase;
        font-weight: 600;
        color: black;
        letter-spacing: 1px; }
      .womenmen .right .content h1 {
        font-weight: 400;
        color: #0c3251; }
      .womenmen .right .content .text {
        max-width: 300px; }
        .womenmen .right .content .text p {
          padding-top: 5px;
          color: black;
          letter-spacing: 0.5px; }
      .womenmen .right .content .link {
        text-align: right;
        padding: 25px 0 15px 0; }
        .womenmen .right .content .link a {
          background-color: #0c3251;
          color: white;
          padding: 15px 50px 15px 30px;
          position: relative;
          right: -50px; }
          .womenmen .right .content .link a:hover {
            background-color: #8baac2;
            color: white; }

.empty-page-content {
  padding: 100px 20px; }
  .empty-page-content h1 {
    font-weight: 400;
    color: #0c3251; }
  .empty-page-content .cart--empty-message {
    padding: 80px 0 100px 0;
    color: black;
    font-size: 30px; }
    .empty-page-content .cart--empty-message span {
      color: black; }
  .empty-page-content .link {
    padding-top: 25px; }
  .empty-page-content a {
    color: white !important;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #0c3251;
    background-image: unset;
    padding: 14px 35px;
    text-transform: uppercase; }
    .empty-page-content a:hover {
      transition: .3s;
      background-color: #8baac2 !important;
      text-decoration: none; }

#MobileNav .mobile-nav__link {
  padding: 15px 20px !important; }
#MobileNav .mobile-nav__return {
  background-color: #0c3251; }
  #MobileNav .mobile-nav__return svg {
    fill: white; }
#MobileNav .mobile-nav__label {
  font-size: 16px !important;
  color: #0c3251 !important;
  letter-spacing: 0.5px !important;
  font-weight: 400 !important; }
#MobileNav a:hover,
#MobileNav li:hover .mobile-nav__link,
#MobileNav .mobile-nav__sublist-link:hover {
  background-color: #0c3251 !important;
  border-bottom: none !important;
  text-decoration: none !important;
  transition: .3s; }
  #MobileNav a:hover span,
  #MobileNav li:hover .mobile-nav__link span,
  #MobileNav .mobile-nav__sublist-link:hover span {
    border-bottom: none !important;
    color: white !important; }
  #MobileNav a:hover svg,
  #MobileNav li:hover .mobile-nav__link svg,
  #MobileNav .mobile-nav__sublist-link:hover svg {
    fill: white; }

.mobile-nav-wrapper::after {
  display: none !important; }

.main-wrapper.reviewBlock {
  padding: 100px 20px 0 !important; }
  .main-wrapper.reviewBlock .jdgm-rev-widg {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important; }
  .main-wrapper.reviewBlock .jdgm-rev-widg__title {
    text-transform: inherit !important;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
    color: #0c3251 !important; }
  .main-wrapper.reviewBlock .jdgm-write-rev-link {
    border-radius: 50px !important;
    background: #0c3251 !important;
    border: none !important;
    color: white !important;
    padding: 10px 30px !important;
    text-transform: uppercase !important;
    letter-spacing: 0.5px !important;
    font-weight: 500 !important;
    font-size: 15px !important; }
    .main-wrapper.reviewBlock .jdgm-write-rev-link:hover {
      text-decoration: none !important;
      background: #8baac2 !important;
      transition: .3s; }
  .main-wrapper.reviewBlock .jdgm-rev-widg__summary {
    float: none !important;
    padding-left: 5px !important; }
    .main-wrapper.reviewBlock .jdgm-rev-widg__summary .jdgm-rev-widg__summary-text {
      color: black !important;
      font-weight: 300 !important;
      letter-spacing: 0.5px !important; }
  .main-wrapper.reviewBlock .jdgm-rev-widg__header {
    padding-bottom: 15px !important;
    border-bottom: 1px solid #dddee0 !important; }
  .main-wrapper.reviewBlock .jdgm-rev-widg__summary-stars {
    display: block !important;
    margin: 0 !important; }
  .main-wrapper.reviewBlock .jdgm-submit-rev {
    border-radius: 50px !important;
    background: #0c3251 !important;
    border: none !important;
    color: white !important;
    padding: 10px 30px !important;
    text-transform: uppercase !important;
    letter-spacing: 0.5px !important;
    font-weight: 500 !important;
    font-size: 15px !important; }
    .main-wrapper.reviewBlock .jdgm-submit-rev:hover {
      text-decoration: none !important;
      background: #8baac2 !important;
      transition: .3s; }
  .main-wrapper.reviewBlock .jdgm-widget label {
    display: none !important; }
  .main-wrapper.reviewBlock .jdgm-widget span {
    display: none !important; }
  .main-wrapper.reviewBlock .jdgm-widget textarea,
  .main-wrapper.reviewBlock .jdgm-widget input:not([type='submit']) {
    margin: 5px 0 !important;
    border-radius: 0 !important;
    color: black !important;
    text-align: center !important;
    font-size: 14px !important; }
    .main-wrapper.reviewBlock .jdgm-widget textarea::placeholder,
    .main-wrapper.reviewBlock .jdgm-widget input:not([type='submit'])::placeholder {
      color: black !important; }
  .main-wrapper.reviewBlock .jdgm-widget form {
    text-align: center !important;
    padding: 10px 0 0 !important;
    max-width: 500px !important;
    margin: 0 auto !important; }

.video-text-container {
  position: absolute;
  z-index: 2;
  background-color: #0c325180;
  color: white;
  text-align: center;
  padding: 30px; }
  @media only screen and (max-width: 767px) {
    .video-text-container {
      position: relative;
      width: 100%; } }
  .video-text-container p {
    margin-bottom: 25px !important; }
  .video-text-container p, .video-text-container a {
    color: white;
    text-align: center; }
  .video-text-container a {
    border: 1px solid white; }
    .video-text-container a:hover {
      background-color: white;
      color: #0c3251 !important; }

.homehero {
  position: relative; }
  .homehero .slide {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .homehero .slide {
        flex-direction: column; } }
    .homehero .slide video {
      position: relative;
      z-index: 0; }

.additional-sizes {
  display: inline-block;
  margin-bottom: 15px !important; }
